import { useState, useEffect } from 'react';

const minDrawerWidthRatio = 0.2;
const maxDrawerWidth = 320;
const drawerWidthMobileRatio = 0.7;
const mobileMaxWidth = 600;

const calcWidth = () => {
  const testDiv = document.createElement('div');
  testDiv.style.position = 'absolute';
  testDiv.style.top = '0';
  testDiv.style.left = '0';
  testDiv.style.width = '100vw';
  testDiv.style.height = '0';
  testDiv.style.overflow = 'hidden';
  document.body.appendChild(testDiv);
  const viewportWidth = testDiv.clientWidth;
  document.body.removeChild(testDiv);
  return viewportWidth;
}
const calcIsMobileScreenSize = () => {
  const width = calcWidth(); //window.innerWidth; //window.screen.width;
  const mob = width <= mobileMaxWidth;
  //console.info("isMobileLS: "+mob+" - windowWidth: "+width);
  return mob;
};

const calcContentWidth = () => {
  if (!calcIsMobileScreenSize()) {
    const drawerWidth = calcDrawerWidth();
    const contentWidth = calcWidth() - drawerWidth;
    //console.info("ContentWidthLS: "+contentWidth);
    return contentWidth;
  }
  // console.info("ContentWidthLS: "+calcWidth());

  return calcWidth();
};

const calcDrawerLeft = () => {
  if (!calcIsMobileScreenSize()) {
    const drawerLeft = calcDrawerWidth();
    // console.info("DrawerLeftLS: "+drawerLeft);
    return drawerLeft;
  }
  // console.info("DrawerLeftLS: "+0);
  return 0;
};

const calcDrawerWidth = () => {
  if (!calcIsMobileScreenSize()) {
    const minDrawerWidth = calcWidth() * minDrawerWidthRatio;
    const drawerWidth = Math.min(minDrawerWidth, maxDrawerWidth);
    // console.info("DrawerWidthLS: "+drawerWidth);
    return drawerWidth;
  }
  // console.info("DrawerWidthLS: "+calcWidth() * drawerWidthMobileRatio);
  return calcWidth() * drawerWidthMobileRatio;
};

const calcIsMobileBrowser = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /android|iphone|ipad|ipod|opera mini|iemobile|wpdesktop/.test(userAgent.toLowerCase());
};

const useWindowMeasurement = (calcFunction) => {
  const [value, setValue] = useState(calcFunction());

  useEffect(() => {
    const updateValue = () => {
      setTimeout(() => {
        const newValue = calcFunction();
        setValue(newValue);
      }, 100); // 100ms delay, adjust as needed
    };

    // Initial calculation
    updateValue();

    // Update on window resize
    window.addEventListener('resize', updateValue);
    window.addEventListener('orientationchange', updateValue);
    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', updateValue);
      window.removeEventListener('orientationchange', updateValue);
    };
  }, [calcFunction]);

  return value;
};

export const useContentWidth = () => useWindowMeasurement(calcContentWidth);
export const useDrawerLeft = () => useWindowMeasurement(calcDrawerLeft);
export const useDrawerWidth = () => useWindowMeasurement(calcDrawerWidth);
export const useIsMobileScreenSize = () => useWindowMeasurement(calcIsMobileScreenSize);
export const useIsMobileBrowser = () => useWindowMeasurement(calcIsMobileBrowser);

