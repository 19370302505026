import axios from 'axios';
import config from '../config';


const backendUrl = config.apiBaseUrl; //process.env.REACT_APP_BACKEND_URL;

const makePOSTRequest = async (request, endpoint, setIsLoading = () => {}, stringifyJson = true) => {
  try {
    setIsLoading(true); // Setze den Ladezustand auf true

    const requestUrl = `${backendUrl}/${endpoint}`;

    let body = request;
    if (stringifyJson) {
      body = JSON.stringify(request);
    }

    const headers = request instanceof FormData ? {} : { 'Content-Type': 'application/json' };

    const response = await axios.post(requestUrl, body, {
      headers: headers,
      timeout: 300000  // Timeout auf 5 Minuten (300.000 Millisekunden) gesetzt
    });

    if (response.status !== 200) {
      console.log("Response not OK: ", response);
      throw new Error('Fehler bei der Anfrage');
    }

    return response.data;
  } catch (error) {
    console.error('Error calling backend Endpoint: ' + endpoint + ' Error: ', error);
  } finally {
    setIsLoading(false);
  }
};

export const makeRequestBinaryObject = async (request, endpoint, setIsLoading, expectedMimeType) => {
  setIsLoading(true);
  try {
    const requestUrl = backendUrl + "/" + endpoint;
    const response = await axios.post(requestUrl, request, { responseType: 'blob' });
    return URL.createObjectURL(new Blob([response.data], { type: expectedMimeType }));
  } finally {
    setIsLoading(false);
  }
}

export const handleSinglePropertySubmit = async (name, value, onExtractStart, handleText, setIsLoading, hasPayed, userId) => {
  // Start the request without waiting
  const extractRequest = axios.post(`${config.apiBaseUrl}/extract-text`, {
    [name]: value,
    has_payed: hasPayed,
    user_id: userId
  });

  onExtractStart();
  console.info("Extracting from URL");

  // Now wait for the request to complete and handle the response
  await extractRequest
    .then(extract_response => {
      console.info("Setting text to: " + extract_response.data.text);
      handleText(extract_response.data.text);
    })
    .catch(error => {
      console.error('Error processing text:', error);
      setIsLoading(false);
    });
}

export const handleUrlSubmit = async (url, onExtractStart, handleText, setIsLoading, hasPayed, userId) => {
  return handleSinglePropertySubmit("url", url, onExtractStart, handleText, setIsLoading, hasPayed, userId);
};

export const handleFileSubmit = async (event, onExtractStart, handleText, setIsLoading, hasPayed, userId) => {
  const file = event.target.files[0];
  const reader = new FileReader();

    // Dateigrößenbegrenzungen
    const maxImageSize = config.maxImageSizeInMb * 1024 * 1024; 
    const maxPdfSize = config.maxPdfSizeInMb * 1024 * 1024;
    const maxAudioSize = config.maxAudioSizeInMb * 1024 * 1024;

    // Dateigröße prüfen
    if (file.type.startsWith('image/') && file.size > maxImageSize) {
      alert('Das Bild ist größer als '+config.maxImageSizeInMb+'. Bitte wähle eine kleinere Datei.');
      return;
    }
  
    if (file.type === 'application/pdf' && file.size > maxPdfSize) {
      alert('Das PDF ist größer als '+config.maxPdfSizeInMb+'. Bitte wähle eine kleinere Datei.');
      return;
    }

    if (file.type.startsWith('audio/') && file.size > maxAudioSize) {
      alert('Die Audiodatei ist größer als '+config.maxAudioSizeInMb+'. Bitte wähle eine kleinere Datei.');
      return;
    }

  const startRequest = async (base64String) => {
    try {
      const extractResponse = await axios.post(`${config.apiBaseUrl}/extract-text`, {
        binary: base64String,
        filename: file.name,
        filetype: file.type,
        has_payed: hasPayed,
        user_id: userId,
      }, {
        timeout: 300000  // Timeout auf 5 Minuten (300.000 Millisekunden) gesetzt
      });
      handleText(extractResponse.data.text);
    } catch (error) {
      console.error('Error processing text:', error);
      setIsLoading(false);
    }
  };

  reader.onloadend = async (e) => {
    if (file.type !== 'text/plain') {
      const base64String = btoa(
        new Uint8Array(e.target.result)
          .reduce((data, byte) => data + String.fromCharCode(byte), '')
      );
      await startRequest(base64String);
    } else {
      const content = e.target.result;
      handleText(content);
    }
  };

  onExtractStart();
  reader.readAsArrayBuffer(file);

};

export const loadSessionData = async (sessionId, onExtractStart, handleText, setIsLoading, hasPayed, userId) => {
  try {
    setIsLoading(true);

    // Lade die Daten von deinem Flask-Backend
    const response = await axios.get(`${config.apiBaseUrl}/get-session-data/${sessionId}`);
    const { filename, text, url } = response.data;
    console.log("got following data: file:" + filename + ", text:" + text + ", url:" + url);

    if (filename) {
      handleSinglePropertySubmit("session_id", sessionId, onExtractStart, handleText, setIsLoading, hasPayed, userId);
    } else if (url) {
      // Wenn es eine URL gibt, übergebe sie an handleUrlSubmit
      console.log("handling url: " + url)
      handleUrlSubmit(url, onExtractStart, handleText, setIsLoading, hasPayed, userId);
    } else if (text) {
      // Wenn es nur Text gibt, übergebe ihn an summarizeText
      console.log("summarizing text: " + text)
      handleText(text, onExtractStart, handleText, setIsLoading, hasPayed, userId);
    }

  } catch (error) {
    console.error('Error loading session data:', error);
    setIsLoading(false);
  }
};

export default makePOSTRequest;
