import { Box, Button, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

const InstallPWAButton = (params) => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true);
    };

    window.addEventListener('beforeinstallprompt', handler);

    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      setDeferredPrompt(null);
    }
  };


  if (!isInstallable && params.altText) {
    return <Box>Eine Installation ist über diesen Browser nicht möglich. Wir empfehlen die Verwendung von <a href="https://www.google.com/chrome/">Google Chrome</a> zur Installation dieser App.</Box>;
  }

  if (!isInstallable) {
    return null;
  }

  if (params.label) {

    return (
      <>
        <Button onClick={handleInstallClick} variant="contained" sx={{ display: isInstallable ? 'block' : 'none' }}>
          Als App installieren
        </Button>
        <Typography variant="caption" >Wir empfehlen die Verwendung von <a href="https://www.google.com/chrome/">Google Chrome</a> zur Installation dieser App.</Typography>
      </>
    );
  } else {
    return (
      <Button onClick={handleInstallClick} variant="contained" sx={{ display: isInstallable ? 'block' : 'none' }}>
        Als App installieren
      </Button>
    );
  }
};

export default InstallPWAButton;
