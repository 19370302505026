function duplicateNewlinesExceptCodeBlocks(text) {
  // Define the regex pattern for Markdown code blocks
  const pattern = /(```.*?)\\n(.*?```)/gs;

  // Create a function that will be called for each match
  const replaceNewlinesExceptCodeBlocks = (match, codeBlockStart, codeBlockContent, codeBlockEnd) => {
    // Remove the code block from the original text and duplicate newlines
    const newText = text.replace(codeBlockStart + codeBlockContent + codeBlockEnd, '');
    const doubleNewlineReplacedText = newText.replace(/\\n/g, '\\n\\n');

    // Add back the code block to the new text
    return doubleNewlineReplacedText + codeBlockStart + codeBlockContent.replace(/\\n/g, '\\n\\n') + codeBlockEnd;
  };

  // Perform the replacement
  return text.replace(pattern, replaceNewlinesExceptCodeBlocks);
}

export default duplicateNewlinesExceptCodeBlocks;
