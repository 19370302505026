import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage, setChatTitle, setChatAvatar, setChatInitialized, disableChat } from '../storage/chatSlice.js';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import ChatInput from '../widgets/chat/ChatInput.js'
import ChatMessage from '../widgets/chat/ChatMessage.js';
import makePOSTRequest from '../services/RestService.js';
import ChatMessageList from '../widgets/chat/ChatMessageList.js';
import duplicateNewlinesExceptCodeBlocks from '../services/EncodingService.js';
import Navigation from '../widgets/navigation/Navigation.js';
import { useDrawerLeft } from '../services/LayoutService.js';
import { selectSetting } from '../storage/settingsSlice.js';


const RagWindowPage = () => {
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { chatId } = useParams();
  const chat = useSelector(state => state.chat.chats[chatId]);
  const messages = useSelector(state => state.chat.chats[chatId].messages) || [];
  const sessionId = useSelector(state => state.chat.chats[chatId].sessionId);
  const isChatInitialized = useSelector(state => state.chat.chats[chatId].isChatInitialized);
  const userId = useSelector(state => state.app.userId);
  const isMounted = useRef(false);
  const [isInputEnabled, setInputEnabled] = useState(true);
  const hasPayed = useSelector(selectSetting('hasPayed')) || false;
  const { trackPageView, trackEvent } = useMatomo();

  useEffect(() => {
    trackPageView();
  }, []);


  const setShowHints = (showHints) => {}

  const handleSend = async () => {
    const input_to_send = duplicateNewlinesExceptCodeBlocks(input);
    try {
      dispatch(addMessage({ chatId: chatId, message: { text: input_to_send, sender: 'user' } }));
      setInput('');
      trackEvent({ category: 'action', action: 'rag-question-submit', name: input_to_send, value: 1 })
      const request = {
        'session_id': sessionId,
        'prompt': input_to_send,
        'history': messages.map((message) => message.text),
        'user_id': userId,
        'has_payed': hasPayed,
      };
      const answer = await makePOSTRequest(request, "chat_document", setIsLoading);
      dispatch(addMessage({ chatId: chatId, message: { text: answer, sender: 'bot' } }));
    } catch (e) {
      console.log("Error while sending message", e);
      dispatch(addMessage({ chatId: chatId, message: { text: "Ein Fehler ist aufgetreten.", sender: 'bot' } }));
    }
  };


  useEffect(() => {

    const initRag = async () => {
      console.log('initializing rag');
      trackEvent({ category: 'action', action: 'rag-init', name: chatId, value: 1 })
      try {
        dispatch(setChatInitialized({ chatId: chatId }));

        const request = {
          'session_id': sessionId,
          'user_id': userId,
          'has_payed': hasPayed,
        };
        const answer = await makePOSTRequest(request, "load_document_from_url", setIsLoading);
        console.log("Answer from rag", answer)
        dispatch(addMessage({ chatId: chatId, message: { text: answer.summary, sender: 'bot' } }));
        dispatch(setChatTitle({ chatId: chatId, title: answer.title }));
        dispatch(setChatAvatar({ chatId: chatId, avatar: answer.type }));
      } catch (e) {
        console.log("Error while initializing rag", e);
        dispatch(addMessage({ chatId: chatId, message: { text: "Ein Fehler ist während der Verarbeitung aufgetreten. Der Chat konnte nicht erstellt werden.", sender: 'bot' } }));
        dispatch(disableChat(chatId))
        setInputEnabled(false)
      }
    };

    if (!isMounted.current && !isChatInitialized) {
      isMounted.current = true;
      initRag();
    }
  }, [chatId, userId, dispatch, sessionId, isChatInitialized, hasPayed]);

  return (
    <Navigation avatar={chat.avatar} setShowHints={setShowHints}>
      <ChatMessageList messageComponent={ChatMessage} messages={messages} isLoading={isLoading} />
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: useDrawerLeft,
          right: 0,
        }}
      >
        {isInputEnabled && !chat.disabled && <ChatInput handleSend={handleSend} setInput={setInput} input={input} />}
      </Box>
    </Navigation>

  );
};

export default RagWindowPage;
