import React, { useRef } from 'react';
import { Box, CircularProgress, Divider } from '@mui/material';
import MyAdsInterstitial from './ads/MyAdsInterstitial';
import { useContentWidth, useDrawerLeft } from '../services/LayoutService';

const LoadingIndicator = (params) => {
  const ref = useRef();

  return (
    <Box
      ref={ref}
      sx={{
        mt:1,
        display: 'flex',
        flexDirection: 'column', // Ändere die Ausrichtung auf Spaltenlayout
        //justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 48,
        paddingTop: 1,
        left: params.isWidget ? 0 : useDrawerLeft,
        width: params.isWidget ? "100%" : useContentWidth,
        height: '100%',
        zIndex: 100,
        backgroundColor: 'rgba(255, 255, 255, 0.8)', // optionaler leichter Hintergrund
      }}>
      <Box
        sx={{
          padding: 1,
          display: 'flex',
          border: "1px solid #aaa",
          borderRadius: 10,
          width: '75%',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: "#fff",
        }}>
        <CircularProgress size={20}/>
        <Box sx={{marginLeft: 2}}>{params.defaultText}</Box>
      </Box>
      <MyAdsInterstitial parentref={ref} />
    </Box>
  );
}

export default LoadingIndicator