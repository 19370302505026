import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Container, Tabs, Tab, Box, Dialog, DialogTitle, DialogActions, Button, TextField, IconButton } from '@mui/material';
import DashboardLayout from '../widgets/dashboard/DashboardLayout';
import { AddCircleOutline, Delete, Edit, Check, Close } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import Navigation from '../widgets/navigation/Navigation';
import { useIsMobileScreenSize } from '../services/LayoutService';
import LoadingIndicator from '../widgets/LoadingIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { addDashboard, defaultLayout, renameDashboard, setDashboards, smallDefaultLayout, deleteDashboard } from '../storage/dashboardSlice';
import { useMatomo } from '@datapunt/matomo-tracker-react'


const DashboardPage = () => {
    const { dashboardId } = useParams();
    const dispatch = useDispatch();
    const dashboards = useSelector(state => state.dashboard.dashboards);
    const navigate = useNavigate();
    const isMobile = useIsMobileScreenSize();
    const [editMode, setEditMode] = useState(null);
    const [editedName, setEditedName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openAddToolDialog, setOpenAddToolDialog] = useState(false);
    const [openDashboardDialog, setOpenDashboardDialog] = useState(false);
    const [newDashboardName, setNewDashboardName] = useState('');
    const [selectedDashboard, setSelectedDashboard] = useState(dashboardId || dashboards[0]?.key || 'home');
    const { trackPageView } = useMatomo()

    useEffect(() => {
        if (dashboards.length === 0) {
            console.log("No dashboards, configuring default");
            const initialLayout = isMobile ? smallDefaultLayout : defaultLayout;
            const newDashboards = [{ key: 'home', name: 'Home', layout: initialLayout }];
            dispatch(setDashboards(newDashboards));
            setSelectedDashboard(newDashboards[0].key);
            navigate(`/dash/${newDashboards[0].key}`, { replace: true });
        }
    }, [dashboards, isMobile, dispatch, navigate]);

    useEffect(() => {
        if (dashboardId && dashboardId !== selectedDashboard) {
            setSelectedDashboard(dashboardId);
        }
    }, [dashboardId, selectedDashboard]);


    useEffect(() => {
        trackPageView();
    }, [])

    const setShowHints = () => { };

    const addNewDashboard = () => {
        const newDashboard = {
            key: uuidv4(),
            name: newDashboardName || `Dashboard ${dashboards.length + 1}`,
            layout: [],
        };
        dispatch(addDashboard(newDashboard));
        setNewDashboardName('');
        setOpenDashboardDialog(false);
        setSelectedDashboard(newDashboard.key);
        navigate(`/dash/${newDashboard.key}`, { replace: true });
    };

    const handleDashboardChange = (event, newValue) => {
        setSelectedDashboard(newValue);
        navigate(`/dash/${newValue}`, { replace: true });
    };

    const handleDeleteDashboard = (key) => {
        const newSelectedDashboard = dashboards[0]?.key === key && dashboards.length > 1 ? dashboards[1].key : dashboards[0]?.key;
        dispatch(deleteDashboard(key));
        setSelectedDashboard(newSelectedDashboard);
        navigate(`/dash/${newSelectedDashboard}`, { replace: true });
    };

    const startEditing = (key, name) => {
        setEditMode(key);
        setEditedName(name);
    };

    const cancelEditing = () => {
        setEditMode(null);
        setEditedName('');
    };

    const saveEditing = (key) => {
        dispatch(renameDashboard({ key, newName: editedName }));
        setEditMode(null);
        setEditedName('');
    };

    return (
        <Navigation title="KI Helfer" setShowHints={setShowHints}>
            {isLoading && (<LoadingIndicator defaultText="Verarbeite Anfrage" isWidget={false} />)}
            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center' }}>
                <Tabs value={selectedDashboard} onChange={handleDashboardChange}>
                    {dashboards.map((dashboard) => (
                        <Tab
                            label={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {editMode === dashboard.key ? (
                                        <>
                                            <TextField
                                                value={editedName}
                                                onChange={(e) => setEditedName(e.target.value)}
                                                size="small"
                                                variant="outlined"
                                            />
                                            <IconButton size="small" onClick={() => saveEditing(dashboard.key)} sx={{ marginLeft: 1 }}>
                                                <Check fontSize="small" />
                                            </IconButton>
                                            <IconButton size="small" onClick={cancelEditing}>
                                                <Close fontSize="small" />
                                            </IconButton>
                                        </>
                                    ) : (
                                        <>
                                            {dashboard.name}
                                            <IconButton size="small" onClick={(e) => { e.stopPropagation(); startEditing(dashboard.key, dashboard.name); }} sx={{ marginLeft: 0.25, paddingRight: 0 }}>
                                                <Edit fontSize="small" />
                                            </IconButton>
                                            <IconButton size="small" onClick={(e) => { e.stopPropagation(); handleDeleteDashboard(dashboard.key); }} sx={{ marginLeft: 0, paddingLeft: 0 }}>
                                                <Delete fontSize="small" />
                                            </IconButton>
                                        </>
                                    )}
                                </Box>
                            }
                            key={dashboard.key}
                            value={dashboard.key}
                            sx={{ textTransform: 'none' }}
                        />
                    ))}
                </Tabs>
                <IconButton color="inherit" onClick={() => setOpenDashboardDialog(true)} sx={{ marginLeft: 'auto' }}>
                    <AddCircleOutline />
                </IconButton>
            </Box>

            <Container maxWidth="false" className="container" sx={{ paddingLeft: 0, '@media (min-width: 600px)': { padding: 0 } }}>
                <Routes>
                    {dashboards.map((dashboard) => (
                        <Route
                            path={`/dash/${dashboard.key}`}
                            element={<DashboardLayout
                                openAddToolDialog={openAddToolDialog}
                                setOpenAddToolDialog={setOpenAddToolDialog}
                                dashboardKey={dashboard.key}
                                setIsLoading={setIsLoading}
                            />}
                            key={dashboard.key}
                        />
                    ))}
                    <Route
                        path="/" exact
                        element={<DashboardLayout
                            openAddToolDialog={openAddToolDialog}
                            setOpenAddToolDialog={setOpenAddToolDialog}
                            dashboardKey={selectedDashboard}
                            setIsLoading={setIsLoading}
                        />}
                    />
                </Routes>
            </Container>
            <Dialog open={openDashboardDialog} onClose={() => setOpenDashboardDialog(false)}>
                <DialogTitle>Neues Dashboard hinzufügen</DialogTitle>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Dashboard-Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={newDashboardName}
                    onChange={(e) => setNewDashboardName(e.target.value)}
                />
                <DialogActions>
                    <Button onClick={() => setOpenDashboardDialog(false)}>Abbrechen</Button>
                    <Button onClick={addNewDashboard}>Hinzufügen</Button>
                </DialogActions>
            </Dialog>
        </Navigation>
    );
};

export default DashboardPage;
