import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    disclaimerShown: false,
  },
  reducers: {
    setDisclamerShown: (state) => {
      state.disclaimerShown = "true";
      state.userId = uuidv4();
      localStorage.setItem("disclaimerShown", "true");
      localStorage.setItem("userId", state.userId);
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
  },
});

export const { setDisclamerShown, setUserId } = appSlice.actions;
export default appSlice.reducer;
