import { Card, CardContent, Typography } from '@mui/material';
import MuiMarkdown from 'mui-markdown';


const WikiMessageCard = (params) => {

  return (
    <div style={{ margin: "20px", width: "100%" }}>
      <Card sx={{ maxWidth: "75%", margin: "auto", padding: "5px" }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {params.message.question}
          </Typography>
          <Typography variant="h5" component="div">
            {params.message.answer}
          </Typography>
          {params.message.url &&
            <Typography sx={{ marginTop: 1.5, marginBottom: 1.5 }} color="text.secondary">
              Quelle: <a href={params.message.url}>{params.message.title}</a>
            </Typography>}
          {!params.message.url &&
            <Typography sx={{ marginTop: 1.5, marginBottom: 1.5 }} color="text.secondary">
              Hinweise:
            </Typography>}
          <Typography component={'span'} variant="body2">
          <MuiMarkdown>{params.message.quote}</MuiMarkdown>
          </Typography>
        </CardContent>
      </Card>
    </div>
  );

}

export default WikiMessageCard;