import React, { useState, useEffect, useRef } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { PlayArrow, Stop } from '@mui/icons-material';

const iconStyle = {
    fontSize: '28px'
};

const buttonStyle = {
    width: '48px',
    height: '48px'
};

const LocalTtsControls = ({ text, autoSpeak }) => {
    const [utterance, setUtterance] = useState(null);
    const hasSpokenRef = useRef(false); // Ref verwenden, um Zwischenzustand zu speichern

    useEffect(() => {
        if (autoSpeak && text && !hasSpokenRef.current) {
            console.info("Speaking in useEffect")
            speak(text);
            hasSpokenRef.current = true; // Ref setzen, nachdem gesprochen wurde
        }
    }, [text, autoSpeak]);

    const speak = (textToSpeak) => {
        console.info("in speak()")

        if ('speechSynthesis' in window) {
            window.speechSynthesis.cancel();

            const newUtterance = new SpeechSynthesisUtterance(textToSpeak);
            newUtterance.lang = "de-DE"; // Sprache auf Deutsch setzen
            window.speechSynthesis.speak(newUtterance);
            setUtterance(newUtterance); // Speichern der aktuellen Instanz

            newUtterance.onend = () => {
                setUtterance(null);
                hasSpokenRef.current = false; // Ref zurücksetzen, um zukünftiges Sprechen zu erlauben
            };
        } else {
            alert('Your browser does not support speech synthesis.');
        }
    };

    const cancelSpeech = () => {
        if (utterance) {
            window.speechSynthesis.cancel(); // Sprachausgabe unterbrechen
            setUtterance(null); // State zurücksetzen
            hasSpokenRef.current = false; // Ref zurücksetzen, um zukünftiges Sprechen zu erlauben
        }
    };

    return (
        <>
            <Tooltip title="Vorlesen">
                <IconButton color={utterance ? 'error' : 'success'} onClick={utterance ? cancelSpeech : () => speak(text)} style={buttonStyle}>
                    {utterance ? <Stop style={iconStyle} /> : <PlayArrow style={iconStyle} />}
                </IconButton>
            </Tooltip>
        </>
    );
};

export default LocalTtsControls;
