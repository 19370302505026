import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import StateLoadingWrapper from './pages/StateLoadingWrapper';
import ChatWindowPage from './pages/ChatWindowPage';
import WikiWindowPage from './pages/WikiWindowPage';
import RagWindowPage from './pages/RagWindowPage';
import SettingsPage from './pages/SettingsPage';
import NewWindowPage from './pages/NewWindowPage';
import { ThemeProvider, useMediaQuery } from '@mui/material';
import { defaultTheme } from './themes';
import ImpressumPage from './pages/ImpressumPage';
import ContactPage from './pages/ContactPage';
import FaqPage from './pages/FaqPage';
import './App.css';
import DashboardPage from './pages/DashboardPage';
import { useDispatch, useSelector } from 'react-redux';
import { initChats } from './storage/chatSlice';
import { selectSetting } from './storage/settingsSlice';
import ToolInstancePage from './pages/ToolInstancePage';
import ErrorPage from './pages/ErrorPage';

const CURRENT_VERSION = 0.2;

const App = () => {
  const dispatch = useDispatch();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const themeSetting = useSelector(selectSetting('theme'));

  useEffect(() => {
    const storedVersion = localStorage.getItem('appVersion') || 0;
    dispatch(initChats());
    
    if (storedVersion < CURRENT_VERSION) {
      console.info("Updating App from Version "+storedVersion+" to "+CURRENT_VERSION);
      localStorage.removeItem('dashboards');
      localStorage.setItem('appVersion', CURRENT_VERSION);
      window.location.reload();
    }
  }, [dispatch]); 

  const darkMode = () => {
    console.log("Theme setting: "+themeSetting)
    console.log("System setting: "+prefersDarkMode)
    if(themeSetting === "system") {
      return prefersDarkMode
    } else {
      return themeSetting === "dark";
    }
  }

  const getTheme = () => {
    if(darkMode()) {
      return defaultTheme;
    }
      return defaultTheme;
  }

  

  return (
    <ThemeProvider theme={getTheme()} >
    <Router>
      <Routes>        
        <Route path="/" exact element={<DashboardPage />} />
        <Route path="/dash/*" element={<DashboardPage />} />
        <Route path="/new" exact element={<NewWindowPage/>} />
        <Route path="/chat" exact element={<NewWindowPage/>} />
        <Route path="/settings" exact element={<SettingsPage/>} />
        <Route path="/contact" exact element={<ContactPage/>} />
        <Route path="/faq" exact element={<FaqPage/>} />
        <Route path="/impressum" exact element={<ImpressumPage/>} />
        <Route path="/chat/:chatId" element={<StateLoadingWrapper destination={<ChatWindowPage />}/>} />
        <Route path="/wiki/:chatId" element={<StateLoadingWrapper destination={<WikiWindowPage />}/>} />
        <Route path="/doc/:chatId" element={<StateLoadingWrapper destination={<RagWindowPage />}/>} />
        <Route path="/share/:toolKey/:sessionId" element={<ToolInstancePage />} />
        <Route path="/tool/:toolKey/:instanceKey" element={<ToolInstancePage />} />
        <Route path="/dash/:dashboardId" element={<DashboardPage />} />
        <Route path="/error/:errorKey" element={<ErrorPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
    </ThemeProvider>
  );
};

export default App;
