import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../storage/chatSlice.js';
import { useParams } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import { Box } from '@mui/material';
import ChatInput from '../widgets/chat/ChatInput.js'
import makePOSTRequest from '../services/RestService.js';
import ChatMessageList from '../widgets/chat/ChatMessageList.js';
import WikiMessageCard from '../widgets/chat/WikiMessageCard.js';
import Navigation from '../widgets/navigation/Navigation.js';

import { useDrawerLeft } from '../services/LayoutService.js';

export const wiki_init_message = () => ({ question: "KI Wiki - die Wikipedia KI", answer: "Eine KI, die Fragen mit Hilfe von Wikipedia beantwortet", title: "", quote: "- Vor allem geeignet zum Suchen einzelner Informationen. \n- Der Gesprächsverlauf wird ignoriert. \n- Es kann nur gefunden werden, was auch in der deutschen Wikipedia steht. \n- Am besten ganze Fragen stellen.", url: "" })

const WikiWindowPage = () => {
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { chatId } = useParams();
  const chat = useSelector(state => state.chat.chats[chatId]);
  const messages = chat.messages || [];
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
  }, []);

  const setShowHints = (showHints) => {}

  const handleSend = async () => {
    setInput('');
    const request = {
      "type": "wiki",
      "content": input,
    };
    try {
      const response = await makePOSTRequest(request, "wiki_backend", setIsLoading);
      console.log(response);
      const answer = JSON.parse(response.content.response);

      let url = "";
      if (typeof answer.title !== 'undefined') {
        // the variable is defined
        url = "https://de.wikipedia.org/wiki/" + answer.title.replace(/ /g, '_')
      } else {
        answer.quote = "Die gesuchte Information konnte nicht gefunden werden. Wahrscheinlich ist sie auf Wikipedia nicht vorhanden."
      }
      dispatch(addMessage({ chatId: chatId, message: { question: input, answer: answer.answer, title: answer.title, quote: answer.quote, url: url } }));
    } catch (error) {
      console.log("Ein Fehler ist aufgetreten.", error);
      dispatch(addMessage({ chatId: chatId, message: { question: input, answer: "Ein Fehler ist aufgetreten.", title: "Fehler", quote: "", url: "" } }));
    }
  };

  return (
    <Navigation avatar={chat.avatar} setShowHints={setShowHints}>
      <ChatMessageList messageComponent={WikiMessageCard} messages={messages} isLoading={isLoading} />
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: useDrawerLeft,
          right: 0,
        }}
      >
        <ChatInput handleSend={handleSend} setInput={setInput} input={input} />
      </Box>
    </Navigation>
  );
};

export default WikiWindowPage;
