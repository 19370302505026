import React from 'react';


const AdsWidget = (props) => {

  const adsHtml = `
  <!DOCTYPE html>
  <html>
  <head>
      <title>Anzeige</title>
  </head>
  <body style="margin: 0;">
  <div class="text-image-container" style="position: relative; width: 100%; height: 100%; text-align: center;"> 
    <div>${props.adsCode}</div>
    <div class="text-overlay" style="position: absolute; bottom: 0; left: 0; right: 0; background-color: rgba(255, 255, 255, 0); color: rgba(0, 0, 0, 0); overflow: hidden; text-align: center;"> <p style="margin: 0">${props.context}</p> </div> 
  </div>   
  </body>
  </html>   
  `
//      <div>${props.context}</div>


  return (
      <iframe
        srcDoc={adsHtml}
        width="100%"
        height="100%"
        allow="encrypted-media"
        title="Anzeige"
      frameBorder="0"
      />
  )
}

export default AdsWidget;
