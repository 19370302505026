import React, { useState, useRef } from 'react';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Box } from '@mui/material';
import { UploadFile, CameraAlt, Public } from '@mui/icons-material';
import { useIsMobileBrowser } from '../../services/LayoutService';

const FileUpload = ({ handleUrlSubmit, handleFileSubmit }) => {
    const fileInputRef = useRef();
    const cameraInputRef = useRef();
    const [urlDialogOpen, setUrlDialogOpen] = useState(false);
    const [url, setUrl] = useState('');
    const isMobile = useIsMobileBrowser();

    // Generiere eine eindeutige ID für jede Instanz
    const uniqueId = Math.random().toString(36).substring(2, 15);

    const iconStyle = {
        fontSize: '48px'
    };

    const buttonStyle = {
        width: '72px',
        height: '72px'
    };

    const handleFileClick = (event) => {
        event.preventDefault();
        if (fileInputRef.current) {
            fileInputRef.current.value = '';  // Reset the input value to ensure onChange is fired even if the same file is selected
            fileInputRef.current.click();
        }
    };

    const handleCameraClick = (event) => {
        event.preventDefault();
        if (cameraInputRef.current) {
            cameraInputRef.current.value = '';  // Reset the input value to ensure onChange is fired even if the same file is selected
            cameraInputRef.current.click();
        }
    };

    const handleUrlClick = (event) => {
        event.preventDefault();
        setUrlDialogOpen(true);
    };

    const handleUrlChange = (event) => {
        setUrl(event.target.value);
    };

    const handleUrlDialogClose = () => {
        setUrlDialogOpen(false);
    };

    const handleUrlDialogSubmit = () => {
        handleUrlSubmit(url);
        setUrlDialogOpen(false);
        setUrl('');
    };

    const handleKeyPress = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            handleUrlDialogSubmit();
        }
    };

    return (
        <Box>
            <input
                accept="*"
                id={`file-upload-${uniqueId}`}
                type="file"
                onChange={handleFileSubmit}
                ref={fileInputRef}
                style={{ display: 'none' }}
            />
            <label htmlFor={`file-upload-${uniqueId}`} onClick={handleFileClick}>
                <IconButton
                    color="primary"
                    component="span"
                    style={buttonStyle}
                >
                    <UploadFile style={iconStyle} />
                </IconButton>
            </label>
            {isMobile && (
                <input
                    accept="image/*"
                    capture="environment"
                    id={`camera-upload-${uniqueId}`}
                    type="file"
                    onChange={handleFileSubmit}
                    ref={cameraInputRef}
                    style={{ display: 'none' }}
                />
            )}
            {isMobile && (
                <label htmlFor={`camera-upload-${uniqueId}`} onClick={handleCameraClick}>
                    <IconButton
                        color="primary"
                        component="span"
                        style={buttonStyle}
                    >
                        <CameraAlt style={iconStyle} />
                    </IconButton>
                </label>
            )}
            <IconButton
                color="primary"
                component="span"
                style={buttonStyle}
                onClick={handleUrlClick}
            >
                <Public style={iconStyle} />
            </IconButton>
            <Dialog open={urlDialogOpen} onClose={handleUrlDialogClose}>
                <DialogTitle>Gib eine Webadresse ein</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="url"
                        label="URL"
                        type="url"
                        fullWidth
                        value={url}
                        onKeyDown={handleKeyPress}
                        onChange={handleUrlChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUrlDialogClose} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleUrlDialogSubmit} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default FileUpload;
