import React from 'react';
import { Box, IconButton, Avatar } from '@mui/material';
import ChatInput from './ChatInput';


const NewChatInput = (props) => {


  return (
    <Box
      sx={{
        display: 'flex',
        position: 'static',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'white',
        alignItems: 'center',
        minHeight: "52px"
      }}
    >
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="Character"
        sx={{ ml: 1, backgroundColor: 'object.background', width: 35, height: 35, border: 1, borderColor: 'object.border' }}
        onClick={props.toggleCharacterDialog}
      >
        <Avatar src={props.avatar} sx={{ width: 25, height: 25 }} />
      </IconButton>

      <Box sx={{ flexGrow: 1 }}>
        <ChatInput handleSend={props.handleSend} setInput={props.setInput} input={props.input} />
      </Box>
    </Box>
  );

}

export default NewChatInput;
