const context = require.context('./tools', false, /\.js$/);

const tools = context.keys().map((key) => {
  const tool = context(key).default;
  const metadata = context(key).metadata;
  const defaultSettings = context(key).defaultSettings;

  return { key: key.replace('./', '').replace('.js', ''), component: tool, metadata, defaultSettings };
});

export default tools;
