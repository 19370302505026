import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';

import Navigation from '../widgets/navigation/Navigation.js';
import DatenschutzErklaerung from '../widgets/DatenschutzErklaerung.js';
import { useMatomo } from '@datapunt/matomo-tracker-react';



const ImpressumPage = () => {
  const setShowHints = (showHints) => {}
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
  }, []);
  
  return (
    <Navigation setShowHints={setShowHints}>
      <Box
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          overflowWrap: 'break-word',
          ml:1,
          mr:1
        }}
      >
        <Typography component='div' sx={{ mb: 3, }} variant="h4">Impressum und Datenschutzerklärung</Typography>

        <Typography variant="h5">Impressum</Typography>
        
        <Typography variant="h6">Angaben gemäß § 5 TMG</Typography>
        <p>MeineFirma GmbH<br />MeineStraße 77<br />12345 Musterhausen<br /></p>
        <p>Handelsregister: H12345<br />Registergericht: Köln</p>
        <p><strong>Vertreten durch:</strong><br />Herr Geschäfts Führer<br />Straße Hausnummer<br />PLZ Ort<br /></p>
        <p><strong>Kontakt</strong><br />
        Telefon: 01234/123456<br />
        Telefax: 01234/1123456<br />
        E-Mail: info@meineseite.de<br />
        <a href="contact">Kontaktformular</a><br />
        </p>
        
        <br />
        
        <Typography variant="h6">Umsatzsteuer-ID</Typography>
        <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br />D987654321</p>

        <br />
        
        <Typography variant="h6">Angaben zur Berufs&shy;haftpflicht&shy;versicherung</Typography>
        <p><strong>Name und Sitz des Versicherers:</strong><br />Exali<br />Adresse der<br />Versicherungs Gesellschaft</p>
        <p><strong>Geltungsraum der Versicherung:</strong><br />Deutschland</p>

        <br />
        
        <Typography variant="h6">EU-Streitschlichtung</Typography>
        <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

        <br />
        
        <Typography variant="h6">Verbraucher&shy;streit&shy;beilegung / Universal&shy;schlichtungs&shy;stelle</Typography>
        <p>Wir nehmen an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil. Zust&auml;ndig ist die Universalschlichtungsstelle des Zentrums f&uuml;r Schlichtung e.V., Stra&szlig;burger Stra&szlig;e 8, 77694 Kehl am Rhein (<a href="https://www.verbraucher-schlichter.de" rel="noopener noreferrer" target="_blank">https://www.verbraucher-schlichter.de</a>).</p>

        <div id="datenschutz" style={{paddingBottom:20}}></div>
        <br />
        <br />

        <DatenschutzErklaerung />
        <br />
      <p><Typography>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></Typography></p>
      <br />      </Box>
    </Navigation>


  );
};

export default ImpressumPage;