import React, { useEffect } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import Navigation from '../widgets/navigation/Navigation.js';
import InstallPWAButton from '../widgets/InstallPWAButton.js';
import { useMatomo } from '@datapunt/matomo-tracker-react';



const FaqPage = () => {
  const setShowHints = (showHints) => {}
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
  }, []);

  return (
    <Navigation setShowHints={setShowHints}>
      <Box
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          overflowWrap: 'break-word',
          ml: 3,
          mr: 3,
        }}
      >
        <Typography component='div' sx={{ mb: 3, }} variant="h4">Fragen und Antworten (FAQ)</Typography>

        <Typography variant="h5">Bedienung und Technik</Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            Warum gibt es verschiedene Charaktere statt eines einzigen Chatbots?
          </AccordionSummary>
          <AccordionDetails>
            Wir können verschiedene KI Modelle und auch verschiedene Konfigurationen hinterlegen. Dadurch kann eine KI noch kreativer werden oder besser Programmieren.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            Warum wird empfohlen für jedes Thema einen eigenen Chat zu erstellen?
          </AccordionSummary>
          <AccordionDetails>
            Dies hat mehrere Gründe. Der wichtigste ist wohl, dass die KI bei einem Themenwechsel häufig schlechtere Ergebnisse liefert.
            Ein weiterer Grund ist die Ausführungszeit. Da mit jeder Nachricht die komplette Historie verarbeitet werden muss wächst die Verarbeitungszeit mit der länge des Chats.
            Irgendwann ist auch eine Grenze erreicht und alte Nachrichten werden gelöscht und nicht mehr berücksichtigt.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            Wie kann ich die App auf meinem Handy installieren?
          </AccordionSummary>
          <AccordionDetails>
            <p>Unter diesem Abschnitt erscheint ein Button, wenn die App mit dem gerade verwendeten Browser installierbar ist. Ansonsten versuche es mit Google Chrome.</p>
            <InstallPWAButton altText={true} label={true}/>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            Warum gehen meine Chats bei jedem Browserstart verloren?
          </AccordionSummary>
          <AccordionDetails>
            Alle Chats werden lokal im Browser gespeichert. Wenn der Browser so eingestellt ist, dass er alle Webseitendaten beim Beenden löscht, so werden auch alle Chats gelöscht.
            Um dies zu verhindern kann man in einigen Browsern eine Ausnahme für diese Seite definieren.
          </AccordionDetails>
        </Accordion>


        <Typography variant="h5" sx={{mt: 5}}>Rechtliches und Datenschutz</Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            Unsere Datenschutzphilosophie
          </AccordionSummary>
          <AccordionDetails>
            Wir finden Datenschutz wichtig. Deswegen werden die Chats lokal gespeichert und die Werbetreibenden bekommen keinen Zugriff auf die Inhalte.
            Auf der anderen Seite steht natürlich die Benutzererfahrung und die Finanzierung. Für eine Gerätesynchronisation (an der wir gerade arbeiten) müssen die Chats allein aus technischen Gründen auf unsere Server übertragen werden. Vor der Aktivierung eines solchen Features wird aber immer eine Warnung angezeigt.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            Entspricht der Service den Regelungen der DSGVO?
          </AccordionSummary>
          <AccordionDetails>
            Ja, wir beachten alle Regularien der DSGVO. Näheres steht in unserer <a href="impressum#datenschutz">Datenschutzerklärung</a>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            Ihr schaltet Werbung. Haben die Werbetreibenden Zugriff auf die Chatinhalte?
          </AccordionSummary>
          <AccordionDetails>
            Nein, die einzigen Informationen, die den Werbetreibenden zur Verfügung steht um relevantere Anzeigen schalten zu können ist der Titel des Chats sowie ggf. eine generierte Zusammenfassung der Inhalte.
            Und die Cookies des Werbetreibenden, falls diese zugelassen wurden.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            Wo werden meine Daten gespeichert?
          </AccordionSummary>
          <AccordionDetails>
            Alle Chatinhalte werden ausschließlich auf Deinem Rechner gespeichert. Dies kann sich in Zukunft mit weiteren Features ändern, dann informieren wir aber explizit darüber.
          </AccordionDetails>
        </Accordion>


      </Box>
    </Navigation>


  );
};

export default FaqPage;