import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { Chat } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import { useMatomo } from '@datapunt/matomo-tracker-react'

import makePOSTRequest from '../services/RestService.js';
import duplicateNewlinesExceptCodeBlocks from '../services/EncodingService.js';
import { addChat, addMessage, setChatTitle } from '../storage/chatSlice.js';

import NewChatInput from '../widgets/chat/NewChatInput.js';
import NewChatDialogs from '../widgets/chat/NewChatDialogs.js';
import { selectSetting } from '../storage/settingsSlice.js';

const NewChatTool = ({ settings, setIsLoading }) => {
    const { sessionId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [input, setInput] = useState('');
    const [newChatDialogOpen, setNewChatDialogOpen] = useState(false);
    const [selectedCharacter, setSelectedCharacter] = useState({
        name: 'KI Assistent',
        type: 'chat',
        id: uuidv4(),
        lastModified: new Date().toISOString(),
        avatar: "/img/avatars/assistant.svg",
        instruction: "Du bist ein hilfreicher Assistent. Bisheriger Gesprächsverlauf: {history}",
        title: "Neuer Chat",
        messages: [],
    })
    const hasPayed = useSelector(selectSetting('hasPayed')) || false;
    const { trackEvent } = useMatomo();
    
    useEffect(() => {
        if (sessionId) {
            const selectedCharacter = {
                name: 'Dokumentenchat',
                title: 'Dokumentenchat',
                type: 'doc',
                avatar: "/img/avatars/website.svg",
                id: sessionId,
                lastModified: new Date().toISOString(),
                sessionId: sessionId
            }

            dispatch(addChat(selectedCharacter));
            console.info("Navigating to /doc/" + sessionId);
            navigate("/doc/" + sessionId)
        }
    }, [sessionId]);


    const handleSend = async () => {
        trackEvent({ category: 'action', action: 'new-chat' })

        try {
            setIsLoading(true);
            dispatch(addChat(selectedCharacter));

            const input_to_send = duplicateNewlinesExceptCodeBlocks(input);
            dispatch(addMessage({ chatId: selectedCharacter.id, message: { text: input_to_send, sender: 'user' } }));
            setInput('');
            const request = {
                'ai_name': selectedCharacter.name,
                'message': input_to_send,
                'history': [],
                'ai_args': {},
                'has_payed': hasPayed,
            };
            getTopic(input_to_send);
            const answer = await makePOSTRequest(request, "chat", () => { });
            dispatch(addMessage({ chatId: selectedCharacter.id, message: { text: answer, sender: 'bot' } }));
            navigate("/chat/" + selectedCharacter.id);
        } catch (error) {
            console.log("Ein Fehler ist aufgetreten.", error);
            dispatch(addMessage({ chatId: selectedCharacter.id, message: { text: "Ein Fehler ist aufgetreten.", sender: 'bot' } }));
        } finally {
            setIsLoading(false);
        }
    };



    const getTopic = async (input_to_send) => {
        try {
            const topic = await makePOSTRequest({ 'message': input_to_send }, "get_topic", () => { });
            dispatch(setChatTitle({ chatId: selectedCharacter.id, title: topic }));
        } catch (error) {
            console.error("Fehler beim generieren des Titels", error)
        }
    }



    return (
        <Box sx={{ paddingTop: 1 }}>
            <NewChatInput
                avatar={selectedCharacter.avatar}
                toggleCharacterDialog={() => setNewChatDialogOpen(!newChatDialogOpen)}
                handleSend={handleSend}
                setInput={setInput}
                input={input}
            />
            <NewChatDialogs
                open={newChatDialogOpen}
                setOpen={setNewChatDialogOpen}
                setSelectedCharacter={setSelectedCharacter}
            />
        </Box>
    );
};

export const metadata = {
    name: 'Neuer Chat',
    icon: <Chat />,
    short_description: 'Startet einen neuen Chat.',
    helpText: 'Klicke auf den Roboter, um eine andere Chat-KI auszuwählen.\n\nHalte das Mikrofon gedrückt, um einen Text einzusprechen. Er wird in der Textbox angezeigt und kann bearbeitet werden, bevor du ihn absendest.',
    height: 2
};

export default NewChatTool;
