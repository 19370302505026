 import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { loadMessages } from '../storage/chatSlice.js';
import LoadingIndicator from '../widgets/LoadingIndicator.js';


const StateLoadingWrapper = (props) => {
    const isMounted = useRef(false);
    const { chatId } = useParams();
    console.log("got chat id from params: ", chatId)
    const [isChatLoaded, setIsChatLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    let chat = useSelector(state => state.chat.chats[chatId])
    console.log("selected chat", chat)


    const loadChat = useCallback( async () => {
        try {
            if (!chat || !chat.messages || (chat.messages && chat.messages.length < 1 ) ){
                console.log("loadChat - loading messages")
                dispatch(loadMessages(chatId))
                return true;
            }
            console.log("chat was already loaded")
            return true; // oder false, abhängig von der Logik
        } catch (error) {
            console.log("Fehler beim laden der Chats:", error);
            return false;
        }
    }, [dispatch, chat, chatId]);

    useEffect(() => {
        console.log("In StateLoadingWrapper useEffect for chatId: ", chatId)     

        if (isMounted.current === chatId) {
            console.log("arleady mounted")
            return
        }
        isMounted.current = chatId;
        console.log("In StateLoadingWrapper useEffect")     

        
        setIsLoading(true);
        if (!chat || !chat.messages || (chat.messages && chat.messages.length < 1 ) ){
            console.log("Chat not loaded -> loading")        
            loadChat().then(chatIsLoaded => {
                console.log("Loading finished: ", chatIsLoaded)        
                setIsChatLoaded(chatIsLoaded);
                setIsLoading(false);
            }).catch(() => {
                console.log("catched error during loading")        
                setIsChatLoaded(false);
                setIsLoading(false);
            });
        } else {
            console.log("Chat already loaded :-)")     
            setIsChatLoaded(true);   
            setIsLoading(false);
        }
    }, [chat, chatId, setIsChatLoaded, setIsLoading, loadChat]);

    if (isLoading) {
        console.log("Print 'Loading...' div")
        return <LoadingIndicator defaultText="Lade ..." />;
    }

    if (!isChatLoaded && !chat) {
        console.log("Could not load chats -> redirecting to home")
        return <Navigate to="/" />;
    }

    console.log("Loaded chats -> rendering")
    return props.destination;
}

export default StateLoadingWrapper;
