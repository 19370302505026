import React, { useState, useEffect, useMemo } from 'react';
import GridLayout from 'react-grid-layout';
import { Container, Paper, Box, IconButton, Dialog, DialogTitle, DialogActions, Button, List, ListItemIcon, ListItemText, ListItemButton, Fab, Typography } from '@mui/material';
import { Fullscreen, FullscreenExit, Delete, Add, Settings, Info } from '@mui/icons-material';
import tools from '../../loadTools';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { v4 as uuidv4 } from 'uuid';
import { useContentWidth, useIsMobileScreenSize } from '../../services/LayoutService';
import SpeakableTextBoxPaper from '../tts/SpeakableTextBoxPaper';
import { useDispatch, useSelector } from 'react-redux';
import { updateDashboardLayout, updateToolSettings } from '../../storage/dashboardSlice';


const DashboardLayout = ({ openAddToolDialog, setOpenAddToolDialog, dashboardKey, setIsLoading }) => {
  const dispatch = useDispatch();
  const dashboards = useSelector(state => state.dashboard.dashboards);
  const layout = useMemo(() => {
    return dashboards.find(d => d.key === dashboardKey)?.layout || [];
  }, [dashboardKey, dashboards]);

  const toolSettings = useSelector(state => state.dashboard.toolSettings);
  const [internalLayout, setInternalLayout] = useState(layout);
  const [fullscreen, setFullscreen] = useState(null);
  const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
  const [currentToolId, setCurrentToolId] = useState(null);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const contentWidth = useContentWidth();

  useEffect(() => {
    setInternalLayout(layout);
  }, [layout]);

  const onLayoutChange = (newLayout) => {
    if (fullscreen) {
      return;
    }
    const updatedLayout = newLayout.map(item => {
      const originalItem = internalLayout.find(i => i.i === item.i);
      return originalItem ? { ...item, toolKey: originalItem.toolKey } : item;
    });
    setInternalLayout(updatedLayout);
    dispatch(updateDashboardLayout({ key: dashboardKey, newLayout: updatedLayout }));
  };

  const toggleFullscreen = (key) => {
    setFullscreen(fullscreen === key ? null : key);
  };

  const removeTool = (key) => {
    setInternalLayout(internalLayout.filter((item) => item.i !== key));
    dispatch(updateDashboardLayout({ key: dashboardKey, newLayout: internalLayout.filter((item) => item.i !== key) }));
  };

  const addTool = (tool) => {
    const instanceKey = uuidv4();
    console.info(internalLayout)
    const width = useIsMobileScreenSize ? 24 : 8;
    const height = tool.metadata.height || 4;
    const toolKey = tool.key;
    const newLayout = [...internalLayout, { i: instanceKey, toolKey, x: 0, y: 0, w: width, h: height, minW: 4, minH: 1 }];
    setInternalLayout(newLayout);
    dispatch(updateDashboardLayout({ key: dashboardKey, newLayout }));
    setOpenAddToolDialog(false);
  };

  const openSettings = (toolId) => {
    setCurrentToolId(toolId);
    setOpenSettingsDialog(true);
  };

  const saveSettings = (toolId, settings) => {
    dispatch(updateToolSettings({ key: toolId, settings }));
    setOpenSettingsDialog(false);
  };

  const showInfo = (toolId) => {
    setCurrentToolId(toolId);
    setOpenInfoDialog(true);
  }


  const currentToolLayoutItem = internalLayout.find(item => item.i === currentToolId);
  const currentTool = currentToolLayoutItem ? tools.find(tool => tool.key === currentToolLayoutItem.toolKey) : null;
  const toolMetadata = currentTool ? currentTool.metadata : null;
  //const toolDefaultSettings = currentTool ? currentTool.defaultSettings : null;
  const SettingsComponent = toolMetadata ? toolMetadata.settingsComponent : null;

  return (
    <Container maxWidth="false" sx={{ marginTop: 0, padding: 0, width: '100%', '@media (min-width: 600px)': { paddingLeft: 0 } }}>
      <GridLayout
        className="layout"
        layout={fullscreen ? internalLayout.map(item => item.i === fullscreen ? { ...item, x: 0, y: 0, w: 24, h: 12 } : { ...item, w: 0, h: 0, static: true }) : internalLayout}
        cols={24}
        // breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        // cols={{ lg: 24, md: 16, sm: 10, xs: 6, xxs: 3 }}
        rowHeight={50}
        width={contentWidth}
        onLayoutChange={onLayoutChange}
        draggableHandle=".drag-handle"
        resizeHandles={['se']}
        compactType={'vertical'}
        isBounded={false}
      >
        {internalLayout.map((item) => {
          const tool = tools.find(tool => tool.key === item.toolKey);
          const settings = toolSettings[item.i];
          if (!tool) return null;
          return (
            <Box
              key={item.i}
              component={Paper}
              elevation={3}
              sx={{
                overflow: 'hidden',
                position: 'relative',
                height: fullscreen === item.i ? 'calc(100vh - 80px)' : 'auto',
                zIndex: fullscreen === item.i ? 1300 : 'auto',
                width: fullscreen === item.i ? '100%' : 'auto',
              }}
            >
              <Box
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid lightgrey', backgroundColor: '#e0e0e0', padding: '4px 4px' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', verticalAlign: 'middle', flex: 1 }}>
                  <Box className="drag-handle" sx={{ cursor: 'move', p: 0, m: 0 }}>{tool.metadata.icon}</Box>
                  <Typography variant="h5" gutterBottom sx={{ ml: 1, mb: 0, mt: 0.5, fontSize: '1.2rem', alignItems: 'center' }}>
                    {(settings && settings.displayName) ? settings.displayName : tool.metadata.name}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton size="small" onClick={(e) => { e.stopPropagation(); toggleFullscreen(item.i); }}>
                    {fullscreen === item.i ? <FullscreenExit fontSize="small" /> : <Fullscreen fontSize="small" />}
                  </IconButton>
                  <IconButton size="small" onClick={(e) => { e.stopPropagation(); openSettings(item.i); }}>
                    <Settings fontSize="small" />
                  </IconButton>
                  <IconButton size="small" onClick={(e) => { e.stopPropagation(); removeTool(item.i); }}>
                    <Delete fontSize="small" />
                  </IconButton>
                  {/* <IconButton size="small" onClick={(e) => { e.stopPropagation(); window.open(`/tool/${item.toolKey}/${item.i}`, '_blank'); }}>
                    <Link fontSize="small" />
                  </IconButton> */}
                  <IconButton size="small" onClick={(e) => { e.stopPropagation(); showInfo(item.i); }}>
                    <Info fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
              <Box
                sx={{
                  height: fullscreen === item.i ? 'calc(100vh - 80px)' : 'calc(100% - 32px)',
                  overflow: 'auto',
                  paddingBottom: 1.5,
                }}
              >
                <tool.component settings={settings} setIsLoading={setIsLoading}/>
              </Box>
            </Box>
          );
        })}
      </GridLayout>
      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        onClick={() => setOpenAddToolDialog(true)}
      >
        <Add />
      </Fab>
      <Dialog open={openAddToolDialog} onClose={() => setOpenAddToolDialog(false)}>
        <DialogTitle>Tool hinzufügen</DialogTitle>
        <List>
          {tools.map((tool) => (
            <ListItemButton onClick={() => addTool(tool)} key={tool.key}>
              <ListItemIcon>{tool.metadata.icon}</ListItemIcon>
              <ListItemText primary={tool.metadata.name} />
            </ListItemButton>
          ))}
        </List>
        <DialogActions>
          <Button onClick={() => setOpenAddToolDialog(false)}>Abbrechen</Button>
        </DialogActions>
      </Dialog>
      {currentToolLayoutItem && (<>
        <Dialog open={openInfoDialog} onClose={() => setOpenInfoDialog(false)}>
          <DialogTitle>Informationen zu {toolMetadata.name}</DialogTitle>
          <Box sx={{ m: 1 }}>
            <SpeakableTextBoxPaper text={toolMetadata.helpText} />
          </Box>
          <DialogActions>
            <Button onClick={() => setOpenInfoDialog(false)}>OK</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openSettingsDialog} onClose={() => setOpenSettingsDialog(false)}>
          <DialogTitle>Tool-Einstellungen</DialogTitle>
          {SettingsComponent && (
            <SettingsComponent
              settings={toolSettings[currentToolLayoutItem.i]}
              onSave={(newSettings) => saveSettings(currentToolLayoutItem.i, newSettings)}
            />
          )}
          <DialogActions>
            <Button onClick={() => setOpenSettingsDialog(false)}>Abbrechen</Button>
          </DialogActions>
        </Dialog>
      </>)}
    </Container>
  );
};

export default DashboardLayout;
