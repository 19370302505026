import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography } from '@mui/material';
import InstallPWAButton from './InstallPWAButton';
import TtsControls from './tts/TtsControls';

const disclaimerText = "Achtung: Ausgaben können Fehler enthalten oder komplett falsch sein! Die App befindet sich noch in der Entwicklung. Weitere Infos findest du in der FAQ. Ich freue mich über jedes Feedback: mjakobs@zinformatik.de"
const cookiesText = "Diese Seite finanziert sich über Werbung. Möchtest du Drittanbieter-Cookies für zielgerichtete Werbung zulassen? Du kannst dies jederzeit in den Einstellungen ändern. Mehr Informationen findest du in unserer Datenschutzerklärung."

const DisclaimerDialog = ({ open, handleOk, handleCancel }) => {
  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Disclaimer</DialogTitle>
      <DialogContent>
        <Box sx={{ m: 0, pl: 0, position: 'relative', display: 'flex' }}>
          <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap', flex: 1 }}>
            <p><b>Achtung:</b> Ausgaben können Fehler enthalten oder komplett falsch sein!</p><p>Die App befindet sich noch in der Entwicklung. Weitere Infos findest du in der <a href="/faq">FAQ</a>.</p><p>Ich freue mich über jedes Feedback:</p><p><a href="mailto:mjakobs@zinformatik.de">mjakobs@zinformatik.de</a></p>
          </Typography>
          <TtsControls text={disclaimerText} />
        </Box>
        <InstallPWAButton altText={false} label={true}/>
        <hr />
        <h3>Cookies</h3>
        <Box sx={{ m: 0, pl: 0, position: 'relative', display: 'flex' }}>
          <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap', flex: 1 }}>
            <p>Diese Seite finanziert sich über Werbung. Möchtest du Drittanbieter-Cookies für zielgerichtete Werbung zulassen?</p>
            <p>Du kannst dies jederzeit in den <a href="/settings">Einstellungen</a> ändern.
            <br />
            Mehr Informationen findest du in unserer <a href="/impressum">Datenschutzerklärung</a>.</p>
          </Typography>
          <TtsControls text={cookiesText} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk}>Zulassen</Button>
        <Button onClick={handleCancel}>Ablehnen</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisclaimerDialog;
