const config = {
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
    maxImageSizeInMb: 10,
    maxDocumentSizeInMb: 3,
    maxAudioSizeInMb: 25,
    maxVideoSizeInMb: 100,
    matomoBaseUrl: "https://count.ki.jakobs.home-cloud.org",
  };
  
  export default config;
  