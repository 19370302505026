import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Switch, FormControl, FormLabel, FormControlLabel, Button, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Paper, FormHelperText, Select, MenuItem } from '@mui/material';

import Navigation from '../widgets/navigation/Navigation.js';
import tools from '../loadTools';
import { useDispatch, useSelector } from 'react-redux';
import { changeSetting, selectSettings } from '../storage/settingsSlice.js';
import { useMatomo } from '@datapunt/matomo-tracker-react';



const SettingsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clearConfirmDialogOpen, setClearConfirmDialogOpen] = useState(false);
  const initialSettings = useSelector(selectSettings);

  const [settings, setSettings] = useState(initialSettings);
  const { trackPageView, trackEvent } = useMatomo();

  useEffect(() => {
    trackPageView();
  }, []);

  const setShowHints = (showHints) => { }



  // const handleThemeChange = (event) => {
  //   setSettings((prevSettings) => ({
  //     ...prevSettings,
  //     theme: event.target.value,
  //   }));
  // };

  const handleDefaultToolChange = (event) => {
    trackEvent({ category: 'settings', action: 'change-setting', name: 'defaultToolKey', value: event.target.value })
    dispatch(changeSetting({ name: 'defaultToolKey', value: event.target.value }));
    setSettings((prevSettings) => ({
      ...prevSettings,
      defaultToolKey: event.target.value,
    }));
  };

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    trackEvent({ category: 'settings', action: 'change-setting', name: name, value: checked ? 1 : 0 })
    dispatch(changeSetting({ name: name, value: checked }));
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: checked,
    }));
  };

  const handleClearConfirmDialogClose = () => {
    setClearConfirmDialogOpen(false);
  };

  const clearLocalStore = () => {
    trackEvent({ category: 'settings', action: 'clear-local-store' })
    localStorage.clear();
    navigate("/")
    window.location.reload();
  };

  const resetDisclaimerShown = () => {
    trackEvent({ category: 'settings', action: 'reset-disclaimer-shown' })
    console.log("Resetting Disclaimer");
    localStorage.setItem("disclaimerShown", "false");
  }

  return (
    <Navigation setShowHints={setShowHints}>
      <Box
        sx={{
          overflow: 'auto',
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 1.5,
        }}
      >

        {/* <FormControl component="fieldset" style={{ width: '90%' }}>
          <FormLabel component="legend">Erscheinungsbild</FormLabel>
          <RadioGroup
            aria-label="theme"
            name="theme"
            value={settings.theme}
            onChange={handleThemeChange}
          >
            <FormControlLabel value="light" control={<Radio />} label="Hell" />
            <FormControlLabel value="dark" control={<Radio />} label="Dunkel" />
            <FormControlLabel value="system" control={<Radio />} label="System" />
          </RadioGroup>
        </FormControl> */}

        <FormControl component="fieldset" style={{ marginTop: 20, width: '90%' }}>
          <FormLabel component="legend">Akzeptiere Cookies</FormLabel>
          <FormHelperText>Siehe auch unsere <a href="impressum#datenschutz">Datenschutzbestimmungen</a></FormHelperText>
          <FormControlLabel
            control={
              <Switch
                checked={settings.cookiesAccepted}
                onChange={handleSwitchChange}
                name="cookiesAccepted"
              />
            }
            label={settings.cookiesAccepted ? "Ja" : "Nein"}
          />
        </FormControl>

        <FormControl component="fieldset" style={{ marginTop: 20, width: '90%' }}>
          <FormLabel component="legend">Standard-Tool</FormLabel>
          <FormHelperText>Wird zum Beispiel bei "Teilen mit..." verwendet</FormHelperText>
          <Select
            labelId="default-tool-select-label"
            id="default-tool-select"
            value={settings.defaultToolKey}
            onChange={handleDefaultToolChange}
            fullWidth
          >
            {tools.map((tool) => (
              <MenuItem value={tool.key}>{tool.metadata.name}</MenuItem>
            ))}
          </Select>
        </FormControl>


        <FormControl component="fieldset" style={{ marginTop: 20, width: '90%' }}>
          <FormLabel component="legend">Öffne Tastatur automatisch</FormLabel>
          <FormHelperText>Auf Mobilgeräten</FormHelperText>
          <FormControlLabel
            control={
              <Switch
                checked={settings.autoOpenKeyboard}
                onChange={handleSwitchChange}
                name="autoOpenKeyboard"
              />
            }
            label={settings.autoOpenKeyboard ? "Ja" : "Nein"}
          />
        </FormControl>



        <FormControl component="fieldset" style={{ marginTop: 20, width: '90%' }}>
          <FormLabel component="legend">Verwende lokale Sprachausgabe</FormLabel>
          <FormHelperText>Etwas schneller, aber meist schlechtere Qualität</FormHelperText>
          <FormControlLabel
            control={
              <Switch
                checked={settings.useLocalTts}
                onChange={handleSwitchChange}
                name="useLocalTts"
              />
            }
            label={settings.useLocalTts ? "Ja" : "Nein"}
          />
        </FormControl>

        <FormControl component="fieldset" style={{ marginTop: 20, width: '90%' }}>
          <FormLabel component="legend">Entwicklermodus aktivieren</FormLabel>
          <FormHelperText>Einstellungen für Entwickler</FormHelperText>
          <FormControlLabel
            control={
              <Switch
                checked={settings.developerMode}
                onChange={handleSwitchChange}
                name="developerMode"
              />
            }
            label={settings.developerMode ? "Ja" : "Nein"}
          />
        </FormControl>

        {settings.developerMode && (
          <Paper style={{ marginTop: 20, width: '90%', padding: 10 }}>
            <Typography sx={{ mb: 1 }} variant="h6">Entwickleroptionen</Typography>
            <FormControl component="fieldset" >
              <FormLabel component="legend">Zahlender Benutzer</FormLabel>
              <FormHelperText>Immitiert einen zahlenden Benutzer (keine Werbung, bessere KI-Modelle,...)</FormHelperText>
              <FormControlLabel
                control={
                  <Switch
                    checked={settings.hasPayed}
                    onChange={handleSwitchChange}
                    name="hasPayed"
                  />
                }
                label={settings.hasPayed ? "Ja" : "Nein"}
              />
            </FormControl>

            <FormControl component="fieldset" style={{ marginTop: 20, width: '90%' }}>
              <FormLabel component="legend">Setze Disclaimer zurück</FormLabel>
              <FormHelperText>Der Disclaimer wird erneut angzeigt (nach erneutem Laden)</FormHelperText>
              <FormControlLabel
                control={
                  <Button sx={{ ml: 1.5 }} onClick={resetDisclaimerShown} variant="contained">Zurücksetzen</Button>
                }
              />
            </FormControl>

            <FormControl component="fieldset" style={{ marginTop: 20, width: '90%' }}>
              <FormLabel component="legend">Lösche alle Daten</FormLabel>
              <FormHelperText>Löscht alle Chats und alle Daten!</FormHelperText>
              <FormControlLabel
                control={
                  <Button sx={{ ml: 1.5 }} onClick={() => setClearConfirmDialogOpen(true)} variant="contained">Alle Daten löschen</Button>
                }
              />
            </FormControl>
          </Paper>
        )}


        <Dialog
          open={clearConfirmDialogOpen}
          onClose={handleClearConfirmDialogClose}
          aria-labelledby="confirm-clear-store"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="confirm-clear-store">
            {"Daten löschen?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Sollen wirklich alle Daten gelöscht werden?
              Dadurch gehen alle Chats und Einstellungen unwiederbringlich verloren.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClearConfirmDialogClose} autoFocus>Nein</Button>
            <Button onClick={clearLocalStore}>
              Ja, Löschen
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Navigation>


  );
};

export default SettingsPage;