import React, { useRef, useEffect } from 'react';
import { Box, List } from '@mui/material';
import LoadingIndicator from '../LoadingIndicator.js'


const ChatMessageList = (params) => {
  const lastMessageRef = useRef(null);
  const bottomRef = useRef(null);


  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToLastMessage = () => {
    lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (!params.isLoading) {
      scrollToLastMessage();
    }
  }, [params.isLoading]);

  useEffect(() => {
    if (!params.isLoading) {
      scrollToBottom();
    }
  }, [params.messages, params.isLoading]);

  return (
    <Box
      sx={{
        position: 'absolute',
        overflow: 'auto',
        backgroundColor: 'white',
        paddingBottom: 14,
      }}
    >
      {params.isLoading && <LoadingIndicator defaultText="Verarbeite Anfrage" />}
      <List>
        {params.messages.map((message, index) => (

          message.sender === 'error' ?
            <params.errorComponent message={message} index={index} key={index} regenerate={params.regenerate} />
            :
            <>
            <params.messageComponent message={message} index={index} key={index} regenerate={params.regenerate} edit={params.edit} />
            {(index + 2 === params.messages.length) && (<div ref={lastMessageRef} style={{position: 'relative', bottom: "40px"}} />)}
            </>

        ))}
      </List>
      <div ref={bottomRef} />
    </Box>

  );

}

export default ChatMessageList;