import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Typography, List, FormControl, FormLabel, FormControlLabel, Switch, DialogActions, Button, TextField } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';

import FileUpload from '../widgets/common/FileUpload';
import QuestionForm from '../widgets/dashboard/QuestionForm';
import SpeakableTextBoxPaper from '../widgets/tts/SpeakableTextBoxPaper';
import { useParams } from 'react-router-dom';
import makePOSTRequest, { handleFileSubmit, handleSinglePropertySubmit, loadSessionData } from '../services/RestService';
import { useSelector } from 'react-redux';
import { selectSetting } from '../storage/settingsSlice';
import { useMatomo } from '@datapunt/matomo-tracker-react'

const ReadingAssistTool = ({ settings = defaultSettings, setIsLoading }) => {
    const { sessionId } = useParams();
    const currentSessionId = useRef();
    const [text, setText] = useState('');
    const [summary, setSummary] = useState('');
    const [history, setHistory] = useState([]);
    const [question, setQuestion] = useState('');
    const hasPayed = useSelector(selectSetting('hasPayed')) || false;
    const userId = useSelector(state => state.app.userId);
    const { trackEvent } = useMatomo();
    
    const summarizeText = useCallback(async (text) => {
        setText(text)
        var sum;
        if (text === "Kein Text gefunden." || text.trim() === "") {
            sum = "Kein Text gefunden.";
        } else {
            const request = {
                instruction: "Als Teil einer Lesehilfe-App gibst du den vom Benutzer eingegebenen Text auf deutsch zurück. Sollte der Text länger sein fasse ihn kurz auf deutsch zusammen und gib dabei an, worum es sich handelt (z.B. \"Rechnung vom Schornsteinfeger\", \"Garantiebedingungen\"). Deine Ausgabe wird automatisiert vorgelesen und sollte AUF DEUTSCH sowie MÖGLICHST KURZ UND INFORMATIV sein. Der Benutzer kann später weitere Fragen stellen.\nHier der Text:",
                user_input: text,
                has_payed: true,
                user_id: userId,
            }
            const response = await makePOSTRequest(request, "llm", setIsLoading);
            sum = response.answer;
            console.log("sum: "+sum);
        }
        setHistory([]);
        console.log("Setting summary to: " + sum)
        setSummary(sum);
    }, [setIsLoading, userId]);

    useEffect(() => {
        if (sessionId && sessionId !== currentSessionId.current) {
            console.log("Loading session: " + sessionId)
            currentSessionId.current = sessionId;
            // Wenn eine sessionId vorhanden ist, lade die entsprechenden Daten
            loadSessionData(sessionId, () => setIsLoading(true), summarizeText, setIsLoading, hasPayed, userId);
        }
    }, [sessionId, summarizeText, setIsLoading, hasPayed, userId]);


    const handleQuestionSubmit = async () => {
        trackEvent({ category: 'action', action: 'reading-assist-question-submit', name: question, value: 1 })
        const request = {
            instruction: `Beantworte AUF DEUTSCH die Frage(n) zum Text, verwende dabei sowohl Informationen aus dem Originaltext als auch aus dem präsentierten Text. ANTWORTE IMMER AUF DEUTSCH! Hier der Originaltext:\n${text}\n\n und hier eine veränderte Form des Textes, die der Benutzer präsentiert bekommen hat:\n${summary}\n\nHier die Benutzeranfrage:`,
            user_input: question,
            has_payed: hasPayed,
            user_id: userId,
        }
        const response = await makePOSTRequest(request, "llm", setIsLoading);
        const answer = response.answer;
        setHistory([...history, { question, answer }]);
        setQuestion('');
    };

    const localHandleSinglePropertySubmit = useCallback(async (name, value) => {
        handleSinglePropertySubmit(name, value, () => setIsLoading(true), summarizeText, setIsLoading, hasPayed, userId)

    }, [summarizeText, setIsLoading, hasPayed, userId]);

    const localHandleUrlSubmit = useCallback(async (url) => {
        trackEvent({ category: 'action', action: 'reading-assist-url-submit', name: url, value: 1 })
        return localHandleSinglePropertySubmit("url", url);
    }, [localHandleSinglePropertySubmit]);

    const localHandleFileSubmit = useCallback(async (event) => {
        trackEvent({ category: 'action', action: 'reading-assist-file-submit', name: event.target.files[0].name, value: 1 })
        handleFileSubmit(event, () => setIsLoading(true), summarizeText, setIsLoading, hasPayed, userId);
    }, [summarizeText, setIsLoading, hasPayed, userId]);


    return (
        <Box sx={{ padding: 2 }}>
            {settings.showDescription && <SpeakableTextBoxPaper text={metadata.short_description} />}
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <FileUpload handleUrlSubmit={localHandleUrlSubmit} handleFileSubmit={localHandleFileSubmit} />
            </Box>
            {summary && (
                <>
                    <SpeakableTextBoxPaper text={summary} autoSpeak={settings.autoSpeak} />
                    {history && (
                        <Box mt={2}>
                            <List>
                                {history.map((entry, index) => (
                                    <Box mb={2}>
                                        <Typography variant='h6'>{entry.question}</Typography>
                                        <SpeakableTextBoxPaper text={entry.answer} autoSpeak={settings.autoSpeak} />
                                    </Box>
                                ))}
                            </List>
                        </Box>
                    )}
                    <QuestionForm
                        question={question}
                        setQuestion={setQuestion}
                        handleQuestionSubmit={handleQuestionSubmit}
                    />
                </>
            )}
        </Box>
    );
};

const ReadingAssistSettings = ({ settings = defaultSettings, onSave }) => {
    const [autoSpeak, setAutoSpeak] = useState(settings.autoSpeak);
    const [showDescription, setShowDescription] = useState(settings.showDescription);
    const [displayName, setDisplayName] = useState(settings.displayName);
    const { trackEvent } = useMatomo();
    
    const handleSave = () => {
        trackEvent({ category: 'settings', action: 'change-readingassist-setting', name: 'autoSpeak', value: autoSpeak ? 1 : 0 })
        trackEvent({ category: 'settings', action: 'change-readingassist-setting', name: 'showDescription', value: showDescription ? 1 : 0 })
        trackEvent({ category: 'settings', action: 'change-readingassist-setting', name: 'displayName', value: displayName })   
        onSave({ autoSpeak, showDescription, displayName });
    };

    const handleAutoSpeakChange = (event) => {
        setAutoSpeak(event.target.checked)
    };

    const handleShowDescriptionChange = (event) => {
        setShowDescription(event.target.checked);
    };

    return (
        <>
            <Box sx={{ padding: 2 }}>
                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                    <FormLabel component="legend">Anzeigename des Tools</FormLabel>
                    <TextField
                        labelId="name-select-label"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                    />
                </FormControl>
                <FormControl component="fieldset" style={{ marginTop: 20, width: '90%' }}>
                    <FormLabel component="legend">Automatisch vorlesen</FormLabel>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={autoSpeak}
                                onChange={handleAutoSpeakChange}
                                name="autoSpeak"
                            />
                        }
                        label={autoSpeak ? "Ja" : "Nein"}
                    />
                </FormControl>
                <FormControl component="fieldset" fullWidth sx={{ marginBottom: 2 }}>
                    <FormLabel component="legend">Zeige Beschreibung an</FormLabel>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showDescription}
                                onChange={handleShowDescriptionChange}
                                name="showDescription"
                            />
                        }
                        label={showDescription ? "Ja" : "Nein"}
                    />
                </FormControl>            </Box>
            <DialogActions>
                <Button onClick={handleSave}>Speichern</Button>
            </DialogActions>
        </>

    );
};

export const metadata = {
    name: 'Lesehilfe',
    icon: <PhotoCamera />,
    short_description: 'Für Menschen mit Leseschwäche. Fasst Texte aus Dokumenten, Fotos oder Webseiten zusammen, liest diese vor und beantwortet Fragen dazu.',
    settingsComponent: ReadingAssistSettings,
    helpText: 'Die Lesehilfe ermöglicht es, Texte aus Dateien, Fotos oder Links zu erfassen. Nach dem Hochladen oder Aufnehmen einer Datei erstellt das System eine kurze Zusammenfassung des Inhalts. Für weitere Details können dann gezielt Fragen gestellt werden.\n\nFür optimale Ergebnisse sollte der Text möglichst scharf sein. Es sollte nur der Text auf dem Foto sein, der relevant ist (z.B. keine Teile anderer Zeitungsartikel).'
};

export const defaultSettings = {
    autoSpeak: true,
    displayName: 'Lesehilfe',
    showDescription: true
};

export default ReadingAssistTool;
