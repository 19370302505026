import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { AppBar, Avatar, Box, Button, Dialog, DialogActions, DialogTitle, IconButton, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import { Edit, Check, Cancel, Menu, HelpOutline, Chat, Dashboard } from '@mui/icons-material';

import { setChatTitle } from '../../storage/chatSlice.js';

import { Delete } from '@mui/icons-material';
import { useLocation, useNavigate } from "react-router-dom";
import { deleteChat } from '../../storage/chatSlice';
import { useContentWidth, useDrawerLeft, useIsMobileScreenSize } from '../../services/LayoutService.js';


export default function ButtonAppBar(props) {
  const navigate = useNavigate();
  const [editing, setEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(props.title);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const path = useLocation().pathname;
  const isInDashBoard = path === "/" || path.indexOf("/dash") > 0;
  const dispatch = useDispatch();
  const chatId = props.chatId;
  const isMobile = useIsMobileScreenSize();
  const helpRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (helpRef.current && !helpRef.current.contains(event.target)) {
        props.toggleHelp();
      }
    };

    if (props.isShowHints) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [props.isShowHints]);

  const handleToggleAreaClick = () => {
    if(isInDashBoard) {
      navigate("/new");
    } else {
      navigate("/");
    }
  }

  const handleEditClick = () => {
    setNewTitle(props.title);
    setEditing(true);
  };

  const handleSaveClick = () => {
    setEditing(false);
    dispatch(setChatTitle({ chatId: chatId, title: newTitle }));
  };

  const handleCancelClick = () => {
    setEditing(false);
    setNewTitle(props.title);
  };

  const handleDeleteOpen = () => {
    setConfirmDeleteDialogOpen(true);
  };

  const handleDeleteClose = () => {
    setConfirmDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = () => {
    dispatch(deleteChat(chatId));
    handleDeleteClose();
    navigate("/")
  };


  return (
    <AppBar
      position="fixed"
      sx={{
        top: 0,
        left: useDrawerLeft,
        backgroundColor: 'appbar.background',
        color: 'appbar.text',
        width: useContentWidth,
        zIndex: 1000
      }}
    >
      <Toolbar variant="dense" sx={{ display: 'flex' }}>
        {isMobile && (<IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="Menü"
          onClick={props.handleDrawerToggle}
          sx={{ paddingLeft: 0 }}
        >
          <Menu />
        </IconButton>)}
        {typeof props.avatar !== 'undefined' && <Avatar
          src={props.avatar}
          sx={{
            mr: 2,
            height: 24,
            width: 24,
            backgroundColor: 'object.background',
            padding: "3px",
            border: 1,
            borderColor: 'object.border'
          }}

        />}
        {editing ? (
          <TextField sx={{ flexGrow: 1 }}
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSaveClick();
              } else if (e.key === "Escape") {
                handleCancelClick();
              }
            }}
            autoFocus
            label="Titel"
            variant="standard"
          />
        ) : (
          <Tooltip title={props.title}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {props.title}
          </Typography>
          </Tooltip>
        )}
        {/* if chatId is not undefined, then we are in a chat and need to show the edit button */}
        {typeof chatId !== 'undefined' && (
          <Box sx={{ right: 0, }}>
            {editing ? (
              <>
                <IconButton size="small" edge="start" color="inherit" onClick={handleSaveClick}>
                  <Check />
                </IconButton>
                <IconButton size="small" edge="start" color="inherit" onClick={handleCancelClick}>
                  <Cancel />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton size="small" edge="start" color="inherit" sx={{ ml: 1 }} onClick={handleEditClick}>
                  <Edit sx={{ width: 18, height: 18 }} />
                </IconButton>
                <IconButton size="small" edge="start" color="inherit" sx={{ ml: 1 }} onClick={handleDeleteOpen}>
                  <Delete sx={{ width: 18, height: 18 }} />
                </IconButton>
              </>
            )}
          </Box>
        ) }
        {isInDashBoard && (
          <IconButton
          size="small"
          edge="end"
          color="inherit"
          aria-label="Hilfe"
          onClick={handleToggleAreaClick}
          sx={{ padding: 0 }}
        >
          <Chat />
        </IconButton>
        )}
        {!isInDashBoard && (
          <IconButton
          size="small"
          edge="end"
          color="inherit"
          aria-label="Hilfe"
          onClick={handleToggleAreaClick}
          sx={{ padding: 0 }}
        >
          <Dashboard />
        </IconButton>
        )}
        {props.showHelpIcon && (
          <IconButton
          ref={helpRef}
          size="small"
          edge="end"
          color="inherit"
          aria-label="Hilfe"
          onClick={props.toggleHelp}
          sx={{ paddingLeft: 1, paddingRight: 0 }}
        >
          <HelpOutline />
        </IconButton>
        )}
      </Toolbar>
      {confirmDeleteDialogOpen && <Dialog open={confirmDeleteDialogOpen} onClose={handleDeleteClose}>
        <DialogTitle>Chat wirklich löschen?</DialogTitle>
        <DialogActions>
          <Button onClick={handleDeleteClose}>Abbrechen</Button>
          <Button onClick={handleDeleteConfirm}>OK</Button>
        </DialogActions>
      </Dialog>}
    </AppBar>
  );
}
