import { ListItem, Card, CardContent, Button } from '@mui/material';

const ErrorMessage = (params) => {

  return (
    <ListItem
      key={params.index}
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Card sx={{
        maxWidth: '85%',
        border: '1px solid #aaa',
        borderRadius: 3,
        padding: 0,
      }}>
        <CardContent sx={{ "& .MuiCardContent-root": { "last-child": { paddingBottom: 0 }, }, }}>
          {params.message.text}
          <br /><br />
          <Button onClick={() => params.regenerate(params.index)} variant="contained">Erneut versuchen</Button>
        </CardContent>
      </Card>
    </ListItem>
  );

}

export default ErrorMessage;