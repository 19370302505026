import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Dialog, DialogActions, DialogTitle, Drawer, Button, List, ListItem, ListItemButton, ListItemAvatar, ListItemText, Avatar, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import { v4 as uuidv4 } from 'uuid';
import { addChat } from '../../storage/chatSlice.js';
import FileUpload from '../common/FileUpload.js';
import makePOSTRequest from '../../services/RestService.js';

export const chatTypes = {
  "KI Assistent": {
    name: 'KI Assistent',
    type: 'chat',
    avatar: "/img/avatars/assistant.svg",
    instruction: "Du bist ein hilfreicher Assistent. Antworte kurz und präzise in der Sprache in der du angepsrochen wurdest.",
    description: "Ausgewogene KI für alle Standardaufgaben.",
    messages: [],
  },
  "Logik-KI": {
    name: 'Logik-KI',
    type: 'chat',
    avatar: "/img/avatars/logic.png",
    instruction: "Du bist ein hilfreicher sehr gebildeter Assistent. Antworte kurz und präzise in der Sprache in der du angepsrochen wurdest.",
    //model: "WizardLM/WizardCoder-Python-34B-V1.0",
    maxTokens: 5000,
    description: "Zum Programmieren, für Logikaufgaben oder zum Erklären komplexer Sachverhalte.",
    messages: [],
  },
  "Kreativ-KI": {
    name: 'Kreativ-KI',
    type: 'chat',
    avatar: "/img/avatars/creative.svg",
    instruction: "Du bist ein hilfsbereiter und sehr kreativer Assistent. Antworte kurz und präzise in der Sprache in der du angepsrochen wurdest.",
    description: "Für Brainstormings, Ideengenerierung und andere kreative Arbeiten.",
    messages: [],
  },
  // "Wikipedia": {
  //   name: 'KI Wiki',
  //   type: 'wiki',
  //   avatar: "/img/avatars/wiki.png",
  //   description: "Stelle Fragen und die KI wird Wikipedia durchsuchen um sie zu beantworten.",
  //   messages: [
  //     { question: "KI Wiki - die Wikipedia KI", answer: "Eine KI, die Fragen mit Hilfe von Wikipedia beantwortet", title: "", quote: "- Vor allem geeignet zum Suchen einzelner Informationen. \n- Der Gesprächsverlauf wird ignoriert. \n- Es kann nur gefunden werden, was auch in der deutschen Wikipedia steht. \n- Am besten ganze Fragen stellen.", url: "" }
  //   ]
  // },
  "Dokument": {
    name: 'Dokumentenchat',
    type: 'doc',
    description: "Chatte mit einem PDF-Dokument, einem Youtube-Video oder einer Website.",
    avatar: "/img/avatars/website.svg",
  }
};

const NewChatDialogs = (props) => {
  const dispatch = useDispatch();
  const open = props.open;
  const [documentDialogOpen, setDocumentDialogOpen] = useState(false);
  const [characterSelectDialogOpen, setCharacterSelectDialogOpen] = useState(false);
  const { trackEvent } = useMatomo();

  const navigate = useNavigate();

  const handleClose = () => {
    props.setOpen(false);
    setDocumentDialogOpen(false);
    setCharacterSelectDialogOpen(false);
  };

  const handleChatOption = async (type) => {
    trackEvent({ category: 'action', action: 'new-chat-dialog-select', name: type, value: 1 });
    const newChatId = uuidv4();
    const mod = new Date().toISOString();
    const chat = chatTypes[type]
    console.log("Handling selected character: ", type);
    console.log(chat);


    if (type === 'Dokument') {
      setDocumentDialogOpen(true);
      return;
    } else if (type === 'Wikipedia') {
      const selectedCharacter = {
        ...chat,
        title: 'Wikipedia',
        id: newChatId,
        lastModified: mod,
      }
      props.setSelectedCharacter(selectedCharacter);
      dispatch(addChat(selectedCharacter));
      handleClose("/wiki/" + newChatId);
      navigate("/wiki/" + newChatId)
    } else if (type === 'KI Charakter') {
      setCharacterSelectDialogOpen(true);
      return;
    } else {
      props.setSelectedCharacter({
        ...chat,
        title: 'Neuer Chat',
        id: newChatId,
        lastModified: mod,
      });
      handleClose("/chat/" + newChatId);
    }
  };

  const handleDocumentChatSubmit = async () => {
    const sessionId = uuidv4();

    const selectedCharacter = {
      name: 'Dokumentenchat',
      title: 'Dokumentenchat',
      type: 'doc',
      avatar: "/img/avatars/website.svg",
      id: sessionId,
      lastModified: new Date().toISOString(),
      sessionId: sessionId
    }
    props.setSelectedCharacter(selectedCharacter);

    dispatch(addChat(selectedCharacter));
    handleClose();
    return sessionId;
  };

  const handleUrlSubmit = async (url) => {
    const sessionId = await handleDocumentChatSubmit();
    await makePOSTRequest({ link: url }, `save/${sessionId}`)
    navigate("/doc/" + sessionId)
  };

  const handleFileSubmit = async (event) => {
    const sessionId = await handleDocumentChatSubmit();
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    
    await makePOSTRequest(formData, `save/${sessionId}`, undefined, false)
    navigate("/doc/" + sessionId)
  };


  return (
    <Drawer
      sx={{ backgroundColor: "#FFF", width: "100%" }}
      open={open}
      anchor='bottom'
      onClose={handleClose}
    >
      <List onClose={handleClose} dense>
        {Object.entries(chatTypes).map(([name, chat]) => (
          <Box key={"box" + name} >
            <Divider />
            <ListItem alignItems="flex-start"
              sx={{
                paddingLeft: 0,
              }}
            >
              <ListItemButton onClick={() => handleChatOption(name)}
              >
                <ListItemAvatar>
                  <Avatar src={chat.avatar} />
                </ListItemAvatar>
                <ListItemText
                  primary={name}
                  secondary={chat.description}
                />
              </ListItemButton>
            </ListItem>
          </Box>
        ))}
      </List>

      <Dialog open={documentDialogOpen} onClose={handleClose}>
        <DialogTitle>Lade ein Dokument hoch - vom Gerät oder aus dem Netz</DialogTitle>

        <FileUpload handleFileSubmit={handleFileSubmit} handleUrlSubmit={handleUrlSubmit} />
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={characterSelectDialogOpen} onClose={handleClose}>
        <DialogTitle>Charakter wählen</DialogTitle>
        <List>
          {['KI Assistent', 'Fitness-Coach', 'Coding Guru', 'IT-Experte', 'Mario Barth', 'Albert Einstein', 'KI Pfarrer'].map((type) => (
            <ListItem onClick={() => handleChatOption(type)} key={type}>
              Chat mit {type.charAt(0).toUpperCase() + type.slice(1)}
            </ListItem>
          ))}
        </List>

      </Dialog>

    </Drawer>
  );
};


export default NewChatDialogs;
