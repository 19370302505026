import { useState } from 'react';
import { ListItem, Card, CardContent, IconButton, Tooltip, TextField, Button, Box } from '@mui/material';
import MuiMarkdown from 'mui-markdown';
import { Highlight, themes } from 'prism-react-renderer';
import { Cached, Edit } from '@mui/icons-material';
import TtsControls from '../tts/TtsControls';

const ChatMessage = (params) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(params.message.text);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    params.edit(params.index, editedText);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedText(params.message.text); // Reset to original text if canceled
  };

  const handleTextChange = (event) => {
    setEditedText(event.target.value);
  };

  return (
    <ListItem
      key={params.index}
      sx={{
        display: 'flex',
        justifyContent: params.message.sender === 'user' ? 'flex-end' : 'flex-start',
      }}
    >
      <Card sx={{
        maxWidth: '85%',
        border: '1px solid #aaa',
        borderRadius: 3,
        padding: 0,
      }}>
        <CardContent sx={{ "& .MuiCardContent-root": { "last-child": { paddingBottom: 0 }, }, }}>
          {params.message.sender !== 'user' && (
            <div style={{ position: 'absolute', top: 3, right: "15.75%" }}>
              <TtsControls text={params.message.text} />
            </div>
          )}
          {!isEditing ? (
            <MuiMarkdown
              Highlight={Highlight}
              themes={themes}
              prismTheme={themes.github}
              hideLineNumbers
            >{params.message.text}</MuiMarkdown>
          ) : (
            <Box>
              <TextField
                value={editedText}
                onChange={handleTextChange}
                fullWidth
                multiline
                variant="outlined"
                sx={{ marginBottom: 1 }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={handleSaveClick} variant="contained" color="primary" sx={{ marginRight: 1 }}>
                  Speichern
                </Button>
                <Button onClick={handleCancelClick} variant="outlined">
                  Abbrechen
                </Button>
              </Box>
            </Box>
          )}
          {params.message.sender === 'bot' ? (
            <Tooltip title="Erneut generieren">
              <IconButton onClick={() => params.regenerate(params.index - 1)} style={{ position: 'absolute', bottom: 3, right: "15.75%" }}>
                <Cached />
              </IconButton>
            </Tooltip>
          ) : (
            !isEditing && (
              <Tooltip title="Editieren">
                <IconButton onClick={handleEditClick} style={{ position: 'absolute', bottom: 3, right: "1%" }}>
                  <Edit />
                </IconButton>
              </Tooltip>
            )
          )}
        </CardContent>
      </Card>
    </ListItem>
  );
}

export default ChatMessage;
