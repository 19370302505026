import React, { useState, useRef, useEffect } from 'react';
import { PlayArrow, Stop } from '@mui/icons-material';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { makeRequestBinaryObject } from '../../services/RestService';
import { useSelector } from 'react-redux';
import { selectSetting } from '../../storage/settingsSlice';

const iconStyle = {
    fontSize: '28px'
};

const buttonStyle = {
    width: '48px',
    height: '48px'
};

const RemoteTtsControls = ({ text, autoSpeak }) => {
    const [audioUrl, setAudioUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [playing, setPlaying] = useState(false);
    const audioRef = useRef();
    const userId = useSelector(state => state.app.userId);
    const hasPayed = useSelector(selectSetting('hasPayed')) || false;

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.onended = () => setPlaying(false);
        }
    }, [audioUrl]);

    useEffect(() => {
        setAudioUrl(null); // Reset audio URL when text changes
        if (autoSpeak) {
            handleSpeak();
        }
    }, [text]); // don't change this. When making all the methods useCallback, playback does no longer work.


    const handleButtonClick = (event) => {
        if (loading) {
            console.info("Loading - do nothing on click")
            return
        }
        if (playing) {
            console.info("Playing - Stopping now")
            handleStop();
        } else {
            console.info("Not Playing - HandleSpeak")
            handleSpeak();
        }
    }

    const handleSpeak = async () => {
        if (loading) {
            console.info("Loading - do nothing on handleSpeak")
            return
        }        
        if (!audioUrl) {
            console.info("No Audio - sending request")
            await getAudio();
            setTimeout(handlePlay, 500);
        } else {
            handlePlay();
        }
    };

    const getAudio = async () => {
        setPlaying(false);
        try {
            console.info("Generating audio for text: " + text)
            const request = {
                text,
                user_id: userId,
                has_payed: hasPayed,
            }
            const url = await makeRequestBinaryObject(request, "speak", setLoading, "audio/mpeg")
            console.info("Got url: " + url)
            setAudioUrl(url);
        } catch (error) {
            console.error('Error speaking:', error);
        }
    }

    const handlePlay = () => {
        if (audioRef.current) {
            console.info("playing now")
            audioRef.current.play();
            setPlaying(true);
        } else {
            console.info("Cannot play as audioRef is missing")
        }
    };

    const handleStop = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setPlaying(false);
        }
    };

    return (
        <>
            <Tooltip title="Vorlesen">
                <IconButton color={playing ? 'error' : 'success'} onClick={handleButtonClick} style={buttonStyle} disabled={loading}>
                    {loading ? <CircularProgress style={iconStyle} /> : (playing ? <Stop style={iconStyle} /> : <PlayArrow style={iconStyle} />)}
                </IconButton>
            </Tooltip>
            {audioUrl && (
                <div>
                    <audio ref={audioRef} src={audioUrl} />
                </div>
            )}
        </>
    );
};

export default RemoteTtsControls;
