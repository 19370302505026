import React from 'react';
import { Box, Divider } from '@mui/material';
import AdsWidget from './AdsWidget';
import { useSelector } from 'react-redux';
import { selectSetting } from '../../storage/settingsSlice';


const MyAdsInterstitial = (props) => {
  const adsCode = `<img src="/img/werbung_interstitial.png" style="width: 100%; height: 100%;" alt="Hier könnte Ihre Werbung stehen" />`
  const hasPayed = useSelector(selectSetting('hasPayed')) || false;

  return (
    <>
      {hasPayed ? (<></>) :
        (<>
          <Divider textAlign="left" sx={{backgroundColor: '#FFF', width: '100%', padding: "2px"}}>Anzeige</Divider>
          <Box sx={{ backgroundColor: '#FFF', height: '375px' }}>
            <AdsWidget adsCode={adsCode} parentref={props.parentref} />
          </Box>
        </>)}
    </>
  )
}

export default MyAdsInterstitial;
