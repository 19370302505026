import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, Box, Alert } from '@mui/material';

import Navigation from '../widgets/navigation/Navigation';
import makePOSTRequest from '../services/RestService';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const ContactPage = () => {
  const initialFormData = {
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendError, setSendError] = useState(false);
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
  }, []);

  const setShowHints = (showHints) => {}

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const clearForm = () => {
    setFormData(initialFormData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSendSuccess(false);
    setSendError(false);
    console.log("Sende: ", formData);
    try {
      await makePOSTRequest(formData, "add_message", () => { });
      setSendSuccess(true);
      clearForm();
    } catch (error) {
      setSendError(true);
    }
  };

  return (
    <Navigation setShowHints={setShowHints}>
      <Box sx={{ m: 10, mt: 0 }}>
        <Typography variant="h3">Kontaktformular</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            key="firstname"
            label="Vorname"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
            margin="normal"
            fullWidth
          />
          <TextField
            label="Nachname"
            name="lastName"
            key="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
            margin="normal"
            fullWidth
          />
          <TextField
            label="E-Mail"
            name="email"
            key="email"
            value={formData.email}
            onChange={handleChange}
            required
            margin="normal"
            fullWidth
            type="email"
          />
          <TextField
            label="Betreff"
            name="subject"
            key="subject"
            value={formData.subject}
            onChange={handleChange}
            required
            margin="normal"
            fullWidth
          />
          <TextField
            label="Nachricht"
            name="message"
            key="message"
            value={formData.message}
            onChange={handleChange}
            required
            margin="normal"
            fullWidth
            multiline
            rows={4}
          />
          <Box >
            <Button type="submit" variant="contained" color="primary" sx={{ mb: 2 }}>
              Senden
            </Button>
            <Button variant="contained" color="secondary" onClick={clearForm} sx={{ mb: 2, ml: 2 }}>
              Felder Leeren
            </Button>
          </Box>
        </form>
        {sendSuccess && <Alert severity="success">Erfolgreich gesendet.</Alert>}
        {sendError && <Alert severity="error">Fehler beim Senden.</Alert>}
      </Box>
    </Navigation>
  );
};

export default ContactPage;
