import { configureStore } from '@reduxjs/toolkit';
import chatReducer from './chatSlice';
import appReducer from './appSlice';
import settingsReducer from './settingsSlice';
import dashboardReducer from './dashboardSlice';

const rootReducer = {
  chat: chatReducer,
  app: appReducer,
  settings: settingsReducer,
  dashboard: dashboardReducer,
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
