import React, { useState, useRef } from 'react';
import { IconButton, CircularProgress } from '@mui/material';
import { Mic } from '@mui/icons-material';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Transcriber = ({ setText }) => {
    const [isRecording, setIsRecording] = useState(false);
    const [isLoadingLocal, setIsLoadingLocal] = useState(false); // Lokaler Zustand für die Ladegrafik
    const mediaRecorderRef = useRef(null);
    const audioChunks = useRef([]);
    const recordingTimeoutRef = useRef(null);

    const startRecording = () => {
        if (!navigator.mediaDevices) {
            return;
        }
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                const mediaRecorder = new MediaRecorder(stream);
                mediaRecorderRef.current = mediaRecorder;
                audioChunks.current = [];
                mediaRecorder.ondataavailable = event => {
                    audioChunks.current.push(event.data);
                };
                mediaRecorder.start();
                setIsRecording(true);
                console.log("Recording started");

                recordingTimeoutRef.current = setTimeout(() => {
                    stopRecording();
                }, 30000);
            })
            .catch(error => console.error('Error accessing media devices.', error));
    };

    const stopRecording = async () => {
        if (recordingTimeoutRef.current) {
            clearTimeout(recordingTimeoutRef.current);
        }
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
            mediaRecorderRef.current.onstop = async () => {
                setIsLoadingLocal(true);
                const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
                console.log("Recording stopped, blob size:", audioBlob.size);
                if (audioBlob.size > 0) {
                    const formData = new FormData();
                    formData.append('file', audioBlob, 'audio.wav');

                    try {
                        const response = await axios.post(`${backendUrl}/transcribe/`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        });
                        setText(response.data.transcript);
                    } catch (error) {
                        console.error('Error transcribing audio', error);
                    } finally {
                        setIsLoadingLocal(false);
                    }
                } else {
                    console.error('Recorded audio is empty');
                    setIsLoadingLocal(false);
                }
            };
        }
    };

    return (
        <>
            {navigator.mediaDevices && <IconButton
                onMouseDown={startRecording}
                onMouseUp={stopRecording}
                onTouchStart={startRecording}
                onTouchEnd={stopRecording}
                edge="end"
            >
                {isLoadingLocal ? <CircularProgress size={24} color="inherit" /> : <Mic color={isRecording ? "secondary" : "inherit"} />}
            </IconButton>}
        </>

    );
};

export default Transcriber;
