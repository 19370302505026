import { createTheme } from '@mui/material/styles';
import { blue, purple, orange } from '@mui/material/colors';

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: orange[500],
    },
    object: {
      background: '#F0F0F0',
      border: '#A0A0A0',
      borderactive: purple[500]
    },
    helptext: {
      color: '#303ffc',
    },
    sidebar: {
      background: '#FAFAFA'
    },
    appbar: {
      background: '#FFF',
      text: '#000'
    }
  },
  typography: {
    helptext: {
      fontFamily: "'Caveat', cursive",
    },
  },
});
