import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setDisclamerShown, setUserId } from '../../storage/appSlice.js';
import { useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { useMatomo } from '@datapunt/matomo-tracker-react'

import { Box } from '@mui/material';

import ButtonAppBar from '../appbar/AppBar.js';
import SideBar from '../navigation/SideBar.js';
import DisclaimerDialog from '../DisclaimerDialog.js';
import { useDrawerWidth } from '../../services/LayoutService.js';
import { changeSetting } from '../../storage/settingsSlice.js';


const Navigation = (props) => {
  const dispatch = useDispatch();
  const { chatId } = useParams();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const chats = useSelector(state => state.chat.chats);
  const disclaimerShown = useSelector(state => state.app.disclaimerShown);
  const [dialogOpen, setDialogOpen] = useState(false);
  const isMounted = useRef(false);
  const setShowHints = props.setShowHints;
  const defaultTitle = props.title || 'KI Helfer';
  const path = useLocation().pathname;
  const { trackEvent } = useMatomo()


  const title = typeof chatId === 'undefined' ? defaultTitle : chats[chatId].title;


  useEffect(() => {
    if (!isMounted.current && !disclaimerShown) {
      isMounted.current = true;

      // check disclaimer
      const isDisclaimerShownStorage = localStorage.getItem("disclaimerShown");
      if (isDisclaimerShownStorage !== "true" && path !== "/settings" && path !== "/impressum" && path !== "/faq") {
        setDialogOpen(true);
        setShowHints(true);
      }

      const userId = localStorage.getItem("userId");
      dispatch(setUserId(userId));


      // check chats
      // const chatStateString = localStorage.getItem('chats');
      // if (chatStateString) {
      //   dispatch(restoreChats(JSON.parse(chatStateString)));
      // }
    }
  }, [disclaimerShown, dispatch, setShowHints, path]);


  const handleCookiesOK = () => {
    dispatch(changeSetting("cookiesAccepted", true));
    handleDialogClose();
    trackEvent({ category: 'settings', action: 'accept-cookies' })
  }

  const handleCookiesDecline = () => {
    dispatch(changeSetting("cookiesAccepted", false));
    handleDialogClose();
    trackEvent({ category: 'settings', action: 'decline-cookies' })
  }

  const handleDialogClose = () => {
    dispatch(setDisclamerShown());
    setTimeout(() => {
      props.setShowHints(false);
    }, 10000)
    setDialogOpen(false);
  };

  const handleDrawerToggle = () => {
    //console.log("Changing drawerOpen to: " + !drawerOpen)
    setDrawerOpen(!drawerOpen);
  };


  const toggleHelp = () => {
    trackEvent({ category: 'settings', action: 'toggle-help', name: 'show-help', value: props.isShowHints ? 1 : 0 })
    props.setShowHints(!props.isShowHints)
  }

  return (
    <Box // Layout
      sx={{
        margin: 0,
        padding: 0,
        display: "flex",
        height: "calc(100dvh - env(keyboard-inset-height))",
      }}
    >
      <DisclaimerDialog open={dialogOpen} handleOk={handleCookiesOK} handleCancel={handleCookiesDecline} />
      <SideBar drawerOpen={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
      <Box // Content

        sx={{
          flex: 1,
          paddingTop: 6,
        }}
      >
        {props.children}
      </Box>
      <Box // AppBar
        sx={{
          position: 'fixed',
          top: 0,
          left: useDrawerWidth,
          right: 0,
        }}
      >
        <ButtonAppBar
          handleDrawerToggle={handleDrawerToggle} title={title}
          chatId={chatId} avatar={props.avatar}
          toggleHelp={toggleHelp}
          isShowHints={props.isShowHints}
          showHelpIcon={props.showHelpIcon}
        />
      </Box>
    </Box>
  );
};

export default Navigation;
