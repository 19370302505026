import React from 'react';
import { Box, Typography } from '@mui/material';


const MainContent = (props) => {


  return (
    <Box sx={{ flexGrow: 1, ml: 3, mr: 3, mb: 6, display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h4" sx={{ textAlign: 'center' }}>
        Willkommen bei KI Helfer
      </Typography>
      <Typography variant="h6" sx={{ textAlign: 'center' }}>
        Der beste kostenlose KI-Chat!
      </Typography>

      <Box
        sx={{
          textAlign: 'center',
          border: 1,
          borderColor: 'object.border',
          backgroundColor: 'object.background',
          padding: .75,
          m: .75,
          borderRadius: "5px",
          maxWidth: 500
        }}
        onClick={() => props.setInput('Mache Vorschläge für ausgefallene Ausflugsziele in Köln.')}>
        <Typography variant="h9" sx={{ textAlign: 'center' }}>
          Mache Vorschläge für ausgefallene Ausflugsziele in Köln.
        </Typography>

      </Box>
      <Box
        sx={{
          textAlign: 'center',
          border: 1,
          borderColor: 'object.border',
          backgroundColor: 'object.background',
          padding: .75,
          m: .75,
          borderRadius: "5px",
          maxWidth: 500
        }}
        onClick={() => props.setInput('Frage mich englische Vokabeln ab.')}
      >
        <Typography variant="h8" sx={{ textAlign: 'center' }}>
          Frage mich englische Vokabeln ab.
        </Typography>
      </Box>
      <Box 
        sx={{
          textAlign: 'center',
          border: 1,
          borderColor: 'object.border',
          backgroundColor: 'object.background',
          padding: .75,
          m: .75,
          borderRadius: "5px",
          maxWidth: 500
        }}
        onClick={() => props.setInput('Schreibe eine Kündigung für meinen Handyvertrag.')}
      >
        <Typography variant="h8" sx={{ textAlign: 'center' }}>
          Schreibe eine Kündigung für meinen Handyvertrag.
        </Typography>
      </Box>
        <Box sx={{flexGrow: 1}}></Box>
    </Box>
  );
};

export default MainContent;
