import { createSlice } from '@reduxjs/toolkit';

// Initial state: Entweder die gespeicherten Settings aus localStorage oder die defaultSettings
const defaultSettings = {
  theme: 'system',
  cookiesAccepted: false,
  autoOpenKeyboard: false,
  useLocalTts: false,
  developerMode: false,
  hasPayed: true,
  defaultToolKey: 'ReadingAssistTool',
};

const initialState = JSON.parse(localStorage.getItem('settings')) || defaultSettings;

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    changeSetting: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
      // Speichere die aktuellen Einstellungen in localStorage
      localStorage.setItem('settings', JSON.stringify(state));
    },
    resetSettings: (state) => {
      Object.assign(state, defaultSettings);
      // Speichere die Standard-Einstellungen in localStorage
      localStorage.setItem('settings', JSON.stringify(state));
    }
  }
});

export const { changeSetting, resetSettings } = settingsSlice.actions;

export const selectSettings = (state) => state.settings;
export const selectSetting = (key) => (state) => state.settings[key];

export default settingsSlice.reducer;
