import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import AdsWidget from './AdsWidget';
import { selectSetting } from '../../storage/settingsSlice';
import { useSelector } from 'react-redux';


const MyAdsBanner = (props) => {
  const adsCode = '<img src="/img/werbung_banner.png" style="width: 100%; max-width: 600px; max-height: 60px" alt="Hier könnte Ihre Werbung stehen" />'
  const hasPayed = useSelector(selectSetting('hasPayed')) || false;

  return (
    <>
      {hasPayed ? (<></>) :
        (
          <Box sx={{ maxHeight: 74, backgroundColor: '#FFF', ml: 2, mr: 2 }}>
            <Divider textAlign="left" sx={{ backgroundColor: '#FFF' }}><Typography variant='h18'>Anzeige</Typography></Divider>
            <AdsWidget adsCode={adsCode} parentref={props.parentref} />
          </Box>
        )}
    </>
  )
}

export default MyAdsBanner;
