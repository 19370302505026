import React, { useRef } from 'react';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import Transcriber from '../common/Transkriber';
import { useIsMobileBrowser } from '../../services/LayoutService';
import { selectSetting } from '../../storage/settingsSlice';
import { useSelector } from 'react-redux';


const ChatInput = (params) => {
  const textareaRef = useRef();
  const isMobileBrowser = useIsMobileBrowser();
  const isAutoOpenKeyboard = useSelector(selectSetting('autoOpenKeyboard'));

  const setAutoFocus = !isMobileBrowser || isAutoOpenKeyboard;

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      params.handleSend();
    } else if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      const input = params.input;
      const cursorPosition = e.target.selectionStart;
      const newInput = [input.slice(0, cursorPosition), '\n', input.slice(cursorPosition)].join('');
      params.setInput(newInput);
      const newCursorPosition = cursorPosition + 1;
      setTimeout(() => {
        e.target.setSelectionRange(newCursorPosition, newCursorPosition);
      }, 0)
    }

  };


  return (
    <Box
      sx={{
        display: 'flex',
        position: 'static',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'white',
        padding: 1,
        alignItems: 'center'
      }}
    >
      <TextField
        inputRef={textareaRef}
        id="input_text"
        value={params.input}
        onChange={e => params.setInput(e.target.value)}
        onKeyDown={handleKeyPress}
        variant="outlined"

        multiline
        autoFocus={setAutoFocus}
        maxRows={4}
        sx={{
          flexGrow: 1,
          '& .MuiOutlinedInput-root': {
            minHeight: '32px', // Mindesthöhe der Textbox
            maxHeight: '128px', // Maximale Höhe, für 4 Zeilen
            borderRadius: '15px',
            padding: '3px 10px 3px 10px',
            bgcolor: 'object.background',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 1, borderColor: 'object.border'
            },
            '& .MuiOutlinedInput-multiline': {
              overflow: 'auto',
              //padding: '2px', // Reduziertes Padding
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,0.2)',
                borderRadius: '8px',
              },
            },
          },
          '& .Mui-focused': {
            //border: 0,
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Transcriber setText={params.setInput} loadInterstitial={false} />
            </InputAdornment>
          )
        }}
      />


      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="Sende"
        sx={{
          ml: 1,
          bgcolor: 'object.background',
          width: '32px',
          height: '32px',
          border: 1,
          borderColor: 'object.border'
        }}
        onClick={params.handleSend}
      >
        <SendIcon
          sx={{ m: 2, padding: 2, height: '20px', width: '20px' }}
        />
      </IconButton >

    </Box>
  );

}

export default ChatInput;
