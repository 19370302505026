import React from 'react';
import LocalTtsControls from './LocalTtsControls';
import RemoteTtsControls from './RemoteTtsControls';
import { selectSetting } from '../../storage/settingsSlice';
import { useSelector } from 'react-redux';

const TtsControls = ({text, autoSpeak}) => {
    const useLocalTts = useSelector(selectSetting('useLocalTts')) || false;

    return (
        <>
            {useLocalTts ? <LocalTtsControls text={text} autoSpeak={autoSpeak} /> : <RemoteTtsControls text={text} autoSpeak={autoSpeak} />}
        </>
    );
};

export default TtsControls;
