import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import store from './storage/store.js';
import "@fontsource/caveat";
import config from './config.js';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

//const userId = localStorage.getItem("userId");

const instance = createInstance({
  urlBase: config.matomoBaseUrl,
  siteId: 1,
  //userId: userId, // optional, default value: `undefined`.
  //trackerUrl: 'https://LINK.TO.DOMAIN/tracking.php', // optional, default value: `${urlBase}matomo.php`
  //srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
  //disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST'
  }
})

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js').then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
      registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                  if (navigator.serviceWorker.controller) {
                      console.log('New version available');
                      alert('Neue Version verfügbar. Bitte die Seite neu laden.');
                  } else {
                      console.log('Content cached for offline use.');
                  }
              }
          };
      };
  }).catch((error) => {
      console.log('Service Worker registration failed:', error);
  });
}



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
        <MatomoProvider value={instance}>
            <App />
        </MatomoProvider>
    </Provider>
  </React.StrictMode>
);

