import { createSlice } from '@reduxjs/toolkit';

export const defaultLayout = [
    {
        "w": 24,
        "h": 2,
        "x": 0,
        "y": 0,
        "i": "f31c8c44-21ed-42ce-999f-b1e61219e6ce",
        "minW": 12,
        "minH": 2,
        "moved": false,
        "static": false,
        "toolKey": "NewChatTool"
    },
    {
        "w": 12,
        "h": 9,
        "x": 0,
        "y": 2,
        "i": "f31c8c44-21ed-42ce-999f-b1e61219e6cd",
        "minW": 4,
        "minH": 4,
        "moved": false,
        "static": false,
        "toolKey": "ReadingAssistTool"
    },
    {
        "w": 12,
        "h": 9,
        "x": 12,
        "y": 2,
        "i": "57ee8492-025f-4b7c-9cc3-0adc44d5b056",
        "minW": 4,
        "minH": 4,
        "moved": false,
        "static": false,
        "toolKey": "TextTool"
    }
];

export const smallDefaultLayout = [
    {
        "w": 24,
        "h": 2,
        "x": 0,
        "y": 0,
        "i": "f31c8c44-21ed-42ce-999f-b1e61219e6cd",
        "minW": 8,
        "minH": 2,
        "moved": false,
        "static": false,
        "toolKey": "NewChatTool"
    },
    {
        "w": 24,
        "h": 6,
        "x": 0,
        "y": 2,
        "i": "g31c8c44-21ed-42ce-999f-b1e61219e6cd",
        "minW": 12,
        "minH": 2,
        "moved": false,
        "static": false,
        "toolKey": "ReadingAssistTool"
    },
    {
        "w": 24,
        "h": 6,
        "x": 0,
        "y": 8,
        "i": "57ee8492-025f-4b7c-9cc3-0adc44d5b056",
        "minW": 12,
        "minH": 2,
        "moved": false,
        "static": false,
        "toolKey": "TextTool"
    }
];

const initialState = {
    dashboards: JSON.parse(localStorage.getItem('dashboards')) || [],
    toolSettings: JSON.parse(localStorage.getItem('toolSettings')) || {},
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setDashboards(state, action) {
            state.dashboards = action.payload;
            localStorage.setItem('dashboards', JSON.stringify(state.dashboards));
          },
        addDashboard(state, action) {
            const newDashboard = {
                key: action.payload.key,
                name: action.payload.name || `Dashboard ${state.dashboards.length + 1}`,
                layout: [],
            };
            state.dashboards.push(newDashboard);
            localStorage.setItem('dashboards', JSON.stringify(state.dashboards));
        },
        deleteDashboard(state, action) {
            state.dashboards = state.dashboards.filter(dashboard => dashboard.key !== action.payload);
            localStorage.setItem('dashboards', JSON.stringify(state.dashboards));
        },
        updateDashboardLayout(state, action) {
            const { key, newLayout } = action.payload;
            const dashboard = state.dashboards.find(d => d.key === key);
            if (dashboard) {
                dashboard.layout = newLayout;
            }
            localStorage.setItem('dashboards', JSON.stringify(state.dashboards));
        },
        updateToolSettings(state, action) {
            state.toolSettings[action.payload.key] = action.payload.settings;
            localStorage.setItem('toolSettings', JSON.stringify(state.toolSettings));
        },
        renameDashboard(state, action) {
            const { key, newName } = action.payload;
            const dashboard = state.dashboards.find(d => d.key === key);
            if (dashboard) {
                dashboard.name = newName;
            }
            localStorage.setItem('dashboards', JSON.stringify(state.dashboards));
        },
    },
});

export const {
    setDashboards,
    addDashboard,
    deleteDashboard,
    updateDashboardLayout,
    updateToolSettings,
    renameDashboard,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
