import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import Navigation from '../widgets/navigation/Navigation';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const errorMessages = {
    FileTooBig: "Die hochgeladene Datei überschreitet das Größenlimit von 10 MB.",
    SessionNotFound: "Die angeforderte Sitzung wurde nicht gefunden.",
    default: "Ein unbekannter Fehler ist aufgetreten."
};

function ErrorPage() {
    const navigate = useNavigate();
    const { errorKey } = useParams();
    const { trackPageView } = useMatomo();

    useEffect(() => {
      trackPageView();
    }, []);
    
    const handleReturn = () => {
        navigate('/');
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    const errorMessage = errorMessages[errorKey] || errorMessages.default;

    const setShowHints = (showHints) => { }

    return (
        <Navigation setShowHints={setShowHints}>

            <Box sx={{ p: 3, textAlign: 'center' }}>
                <Typography variant="h4" gutterBottom>
                    Fehler
                </Typography>
                <Typography variant="body1" paragraph>
                    {errorMessage}
                </Typography>
                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                    <Button
                        variant="contained"
                        startIcon={<ArrowBackIcon />}
                        onClick={handleGoBack}
                    >
                        Zurück
                    </Button>
                    <Button
                        variant="contained"
                        startIcon={<HomeIcon />}
                        onClick={handleReturn}
                    >
                        Zur Hauptseite
                    </Button>
                </Box>
            </Box>
        </Navigation>
    );
}

export default ErrorPage;