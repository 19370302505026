import React from 'react';
import { Box, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import TtsControls from './TtsControls';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';

const iconStyle = {
  fontSize: '24px'
};

const buttonStyle = {
  width: '40px',
  height: '40px'
};

const SpeakableTextBoxPaper = ({ text, autoSpeak, showCopy, showShare }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(text);
  };

  const shareText = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          text: text
        });
      } else {
        console.log("Teilen wird von diesem Browser nicht unterstützt.");
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log("Der Benutzer hat das Teilen abgebrochen.");
      } else {
        console.error("Beim Teilen ist ein Fehler aufgetreten:", error);
      }
    }
  };
  

  return (
    <Box sx={{ m: 0 }}>
      <Paper elevation={3} sx={{ padding: 1, paddingRight: 0, position: 'relative', display: 'flex' }}>
        <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap', flex: 1 }}>
          {text}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <TtsControls text={text} autoSpeak={autoSpeak} />
          {showCopy && (
            <Tooltip titolo="In die Zwischenablage kopieren">
              <IconButton onClick={copyToClipboard} style={buttonStyle}>
                <ContentCopyIcon style={iconStyle} />
              </IconButton>
            </Tooltip>
          )}
          {navigator.share && showShare && (
            <Tooltip title="Teilen">
              <IconButton onClick={shareText} style={buttonStyle}>
                <ShareIcon style={iconStyle} />
              </IconButton>
            </Tooltip>
          )}
          <Box sx={{ flex: 1 }} />
        </Box>
      </Paper>
    </Box>
  );
};

export default SpeakableTextBoxPaper;