import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Box, Divider, Typography, useMediaQuery } from '@mui/material';
import { North, South } from '@mui/icons-material';

import makePOSTRequest from '../services/RestService.js';
import duplicateNewlinesExceptCodeBlocks from '../services/EncodingService.js';
import Navigation from '../widgets/navigation/Navigation.js';
import NewChatInput from '../widgets/chat/NewChatInput.js';
import { addChat, addMessage, setChatTitle } from '../storage/chatSlice.js';
import LoadingIndicator from '../widgets/LoadingIndicator.js';
import MainContent from '../widgets/MainContent.js';
import NewChatDialogs from '../widgets/chat/NewChatDialogs.js';
import MyAdsBanner from '../widgets/ads/MyAdsBanner.js';
import { useDrawerLeft } from '../services/LayoutService.js';
import { selectSetting } from '../storage/settingsSlice.js';
import { useMatomo } from '@datapunt/matomo-tracker-react';




const NewWindowPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isShowHints, setShowHints] = useState(false);
  const [newChatDialogOpen, setNewChatDialogOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const ref = useRef(null);
  const hasPayed = useSelector(selectSetting('hasPayed')) || false;
  const userId = useSelector(state => state.app.userId);
  const { trackPageView, trackEvent } = useMatomo();


  const [selectedCharacter, setSelectedCharacter] = useState({
    name: 'KI Assistent',
    type: 'chat',
    id: uuidv4(),
    lastModified: new Date().toISOString(),
    avatar: "/img/avatars/assistant.svg",
    instruction: "Du bist ein hilfreicher Assistent.",
    title: "Neuer Chat",
    messages: [],
  })


  useEffect(() => {
    trackPageView();
  }, []);

  const getTopic = async (input_to_send) => {
    try {
      const topic = await makePOSTRequest({ 'message': input_to_send, 'has_payed': hasPayed, 'user_id': userId }, "get_topic", () => { });
      dispatch(setChatTitle({ chatId: selectedCharacter.id, title: topic }));
    } catch (error) {
      console.error("Fehler beim generieren des Titels", error)
    }
  }

  const handleSend = async () => {
    trackEvent({ category: 'action', action: 'new-chat-submit', name: input, value: 1 })

    try {
      setIsLoading(true);
      dispatch(addChat(selectedCharacter));

      const input_to_send = duplicateNewlinesExceptCodeBlocks(input);
      dispatch(addMessage({ chatId: selectedCharacter.id, message: { text: input_to_send, sender: 'user' } }));
      setInput('');
      const request = {
      'ai_name': selectedCharacter.name,
      'message': input_to_send,
      'history': [],
      'ai_args': {},
      'has_payed': hasPayed,
      'user_id': userId,
      };
      getTopic(input_to_send);
      const answer = await makePOSTRequest(request, "chat", () => { });
      dispatch(addMessage({ chatId: selectedCharacter.id, message: { text: answer, sender: 'bot' } }));
      navigate("/chat/" + selectedCharacter.id);
    } catch (error) {
      console.log("Ein Fehler ist aufgetreten.", error);
      dispatch(addMessage({ chatId: selectedCharacter.id, message: { text: "Ein Fehler ist aufgetreten.", sender: 'bot' } }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Navigation setShowHints={setShowHints} isShowHints={isShowHints} showHelpIcon={true}>

      {isLoading ? <LoadingIndicator defaultText="Verarbeite Anfrage" /> :
        <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 2 }}>
          {isShowHints && isMobile && <Box sx={{ ml: 2, mt: -1.75, display: 'flex', alignItems: 'center' }}>
            <North sx={{ color: 'primary.main', }} />
            <Typography variant="h5" sx={{ color: 'primary.main', fontFamily: 'helptext.fontFamily' }}>
              Greife auf deine Chats und Einstellungen zu
            </Typography>
          </Box>}
          <MainContent setInput={setInput} />
          <Box // Input
            ref={ref}
            sx={{
              position: 'fixed',
              bottom: 0,
              left: useDrawerLeft,
              right: 0,
            }}
          >
            <NewChatDialogs
              open={newChatDialogOpen}
              setOpen={setNewChatDialogOpen}
              setSelectedCharacter={setSelectedCharacter}
            />

            {isShowHints ? <Box sx={{ ml: 1.75, mb: 0.25, display: 'flex', alignItems: 'center' }}>
              <South sx={{ color: 'primary.main', }} />
              <Typography variant="h5" sx={{ color: 'primary.main', fontFamily: 'helptext.fontFamily' }}>
                Wähle eine KI
              </Typography>
            </Box> :
            <MyAdsBanner context="Ein wenig Context" parentref={ref} />}
            <Divider />
            <NewChatInput
              avatar={selectedCharacter.avatar}
              toggleCharacterDialog={() => setNewChatDialogOpen(!newChatDialogOpen)}
              handleSend={handleSend}
              setInput={setInput}
              input={input}
            />
          </Box>
        </Box>
      }

    </Navigation>
  );
};

export default NewWindowPage;
