import React from 'react';
import { Box, TextField, Button, InputAdornment } from '@mui/material';
import { Send } from '@mui/icons-material';
import Transcriber from '../common/Transkriber';

const QuestionForm = ({ question, setQuestion, handleQuestionSubmit }) => {
    const handleKeyPress = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            handleQuestionSubmit();
        }
    };

    return (
        <Box>
            <TextField
                fullWidth
                variant="outlined"
                label="Frage stellen"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                onKeyDown={handleKeyPress}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Transcriber setText={setQuestion} />
                        </InputAdornment>
                    )
                }}
            />
            <Box mt={2}>
                <Button variant="contained" color="primary" onClick={handleQuestionSubmit} startIcon={<Send />}>
                    Absenden
                </Button>
            </Box>
        </Box>
    );
};

export default QuestionForm;