import { createSlice } from '@reduxjs/toolkit';


// Speichert die Chats-Metadaten (ohne Nachrichten)
const saveChatMetadata = (state) => {
  const chatMetadata = {};
  Object.keys(state).forEach(chatId => {
    const { messages, ...metadata } = state[chatId];
    chatMetadata[chatId] = metadata;
  });
  localStorage.setItem('chatMetadata', JSON.stringify(chatMetadata));
};

// Lädt die Nachrichten eines spezifischen Chats
const loadChatMetadata = (state) => {
  const chatMetadata = localStorage.getItem('chatMetadata');
  return chatMetadata ? JSON.parse(chatMetadata) : {};
};

// Speichert die Nachrichten eines spezifischen Chats
const saveChatMessages = (chatId, messages) => {
  localStorage.setItem(`chat_${chatId}_messages`, JSON.stringify(messages));
};

// Lädt die Nachrichten eines spezifischen Chats
const loadChatMessages = (chatId) => {
  const messages = localStorage.getItem(`chat_${chatId}_messages`);
  return messages ? JSON.parse(messages) : [];
};

// Migrationsfunktion, um alte Daten ins neue Format zu überführen
const migrateOldFormat = () => {
  const oldChats = localStorage.getItem('chats');
  if (oldChats) {
    const chats = JSON.parse(oldChats);
    const newChats = {};

    Object.keys(chats).forEach(chatId => {
      const chat = chats[chatId];
      const { messages, ...metadata } = chat;
      newChats[chatId] = metadata;
      saveChatMessages(chatId, messages || []);
    });

    saveChatMetadata(newChats);
    localStorage.removeItem('chats');  // Entferne den alten Eintrag
    console.log('Migration completed: old format converted to new format.');
  } else {
    console.log("Nothing to migrate :-)")
  }
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    chats: {},
  },
  reducers: {
    addChat: (state, action) => {
      state.chats[action.payload.id] = { ...action.payload, messages: [] };

            // remove all chats that are disabled
            const chatId = action.payload;
            for (var id in state.chats) {
              console.log("Checking chat " + id)
              if (state.chats[id].disabled === true && id !== chatId) {
                console.log("Deleting chat " + id)
                delete state.chats[id];
              }
            }
      
      saveChatMetadata(state.chats);
    },
    deleteChat: (state, action) => {
      console.log('deleting chat ' + action.payload);
      delete state.chats[action.payload];
      localStorage.removeItem(`chat_${action.payload}_messages`);
      saveChatMetadata(state.chats);
    },
    disableChat: (state, action) => {
      console.log('disabling chat ' + action.payload);
      state.chats[action.payload].disabled = true;
      saveChatMetadata(state.chats);
    },
    setChatTitle: (state, action) => {
      console.log("Setting title to ", action.payload.title)
      state.chats[action.payload.chatId].title = action.payload.title;
      state.chats[action.payload.chatId].isTopicGenerated = true;
      saveChatMetadata(state.chats);
    },
    setChatAvatar: (state, action) => {
      state.chats[action.payload.chatId].avatar = "/img/avatars/" + action.payload.avatar + ".svg";
      saveChatMetadata(state.chats);
    },
    setChatInitialized: (state, action) => {
      state.chats[action.payload.chatId].isChatInitialized = true;
      saveChatMetadata(state.chats);
    },
    addMessage: (state, action) => {
      const { chatId, message } = action.payload;
      if (!state.chats[chatId].messages) {
        console.log("Loading messages for adding new Message")
        state.chats[chatId].messages = loadChatMessages(chatId);
      }
      state.chats[chatId].messages.push(message);
      state.chats[chatId].lastModified = new Date().toISOString();

      saveChatMessages(chatId, state.chats[chatId].messages);
      saveChatMetadata(state.chats);
    },
    loadMessages: (state, action) => {
      const chatId = action.payload;
      try {
        console.log("Loading messages in loadMessage call for chatId: "+chatId)
        state.chats[chatId].messages = loadChatMessages(chatId);
      } catch (error) {
        console.error("Could not load messages for chat "+chatId+": "+error)
      }
    },
    removeMessagesAfter: (state, action) => {
      const { chatId, index } = action.payload;
      let messages = state.chats[chatId].messages;
      console.log("index: ", index, " messages.length: ", messages.length)
      while (messages.length > index + 1) {
        messages.pop()
      }
      state.chats[chatId].lastModified = new Date().toISOString();
//      console.log("after loop - index: ", index, " messages.length: ", messages.length)
      saveChatMessages(chatId, state.chats[chatId].messages);
      saveChatMetadata(state.chats);
    },
    initChats: (state) => {
      migrateOldFormat();
      state.chats = loadChatMetadata(state)
    }
  },
});


// ======================================



export const { addChat, deleteChat, disableChat, setChatTitle, setChatAvatar, setChatInitialized, addMessage, removeMessagesAfter, loadMessages, initChats } = chatSlice.actions;
export default chatSlice.reducer;
