import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Settings, Chat, Dashboard, HelpCenter } from '@mui/icons-material';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Avatar, ListSubheader, Tooltip } from '@mui/material';
import NewChatDialogs from '../chat/NewChatDialogs';
import { useDrawerLeft, useDrawerWidth, useIsMobileScreenSize } from '../../services/LayoutService';
import InstallPWAButton from '../InstallPWAButton';

const SideBar = (props) => {
  const isMobile = useIsMobileScreenSize();
  const drawerWidth = useDrawerWidth();
  const drawerLeft = useDrawerLeft();
  const [newChatDialogOpen, setNewChatDialogOpen] = useState(false);
  const navigate = useNavigate();
  const chats = useSelector(state => state.chat.chats);
  const container = window !== undefined ? () => window.document.body : undefined;

  const drawer = (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', height: "100%", overflow: 'hidden', backgroundColor: 'object.background' }}
      role="presentation"
    >

      <List>
        <ListItem key="install" disablePadding dense>
          <Box sx={{marginLeft: 1.5}} >
          <InstallPWAButton />
          </Box>
        </ListItem>
        <ListItem key="dashboard" disablePadding dense>
          <ListItemButton onClick={() => { navigate("/"); props.handleDrawerToggle() }}
            sx={{
              paddingLeft: "8px",
            }}
          >
            <ListItemIcon sx={{ minWidth: "30px" }}>
              <Dashboard color='black' />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </ListItem>
        <ListItem key="newchat" disablePadding dense>
          <ListItemButton onClick={() => { navigate("/new"); props.handleDrawerToggle() }}
            sx={{
              paddingLeft: "8px",
            }}
          >
            <ListItemIcon sx={{ minWidth: "30px" }}>
              <Chat color='black' />
            </ListItemIcon>
            <ListItemText primary="Neuer Chat" />
          </ListItemButton>
        </ListItem>
      </List>

      <Box sx={{ flexGrow: 1, overflow: 'auto', minHeight: 200 }}>
        <List
          subheader={
            <ListSubheader component="div" id="chats-label" sx={{ textAlign: 'center', backgroundColor: 'object.background', lineHeight: "24px" }}>
              Chats
            </ListSubheader>
          }>
          {Object.entries(chats)
            .sort(([keya, valuea], [keyb, valueb]) => new Date(valueb.lastModified) - new Date(valuea.lastModified))
            .filter(([id, chat]) => chat.disabled !== true)
            .map(([id, chat]) => (
              <ListItem key={chat.id} disablePadding dense >
                <Tooltip title={chat.title}>
                  <ListItemButton onClick={() => { navigate(`/${chat.type}/${chat.id}`); props.handleDrawerToggle() }}
                    sx={{
                      paddingLeft: "8px",
                    }}
                  >
                    <Avatar src={chat.avatar} sx={{ marginRight: 1, padding: 0, width: 24, height: 24 }} />
                    <ListItemText primary={chat.title} sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 1) 90%, rgba(255, 255, 255, 0) 98%)',
                      backgroundSize: '200% 100%',
                      //backgroundPosition: '252px bottom',
                    }}

                    />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            ))}
        </List>
      </Box>
      <List>

        <ListItem key="faq" disablePadding dense>
          <ListItemButton onClick={() => navigate("/faq")}
            sx={{
              paddingLeft: "8px",
            }}
          >
            <ListItemIcon sx={{ minWidth: "30px" }}>
              <HelpCenter />
            </ListItemIcon>
            <ListItemText primary="Hilfe & FAQ" />
          </ListItemButton>
        </ListItem>

        <ListItem key="settings" disablePadding dense>
          <ListItemButton onClick={() => navigate("/settings")}
            sx={{
              paddingLeft: "8px",
            }}
          >
            <ListItemIcon sx={{ minWidth: "30px" }}>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Einstellungen" />
          </ListItemButton>
        </ListItem>

        <ListItem key="impressum" disablePadding dense>
          <ListItemButton onClick={() => navigate("/impressum")}
            sx={{
              paddingLeft: "8px",
            }}
          >
            <ListItemIcon sx={{ minWidth: "30px", fontWeight: 'bold', paddingLeft: 1 }}>
              <strong>§</strong>
            </ListItemIcon>
            <ListItemText primary="Impressum" sx={{ marginLeft: -1 }} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{ width: drawerLeft, flexShrink: { sm: 0 } }}
    >
      {isMobile && (
        <Drawer
          container={container}
          variant="temporary"
          open={props.drawerOpen}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      )}
      {!isMobile && (
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open={props.drawerOpen}

        >
          {drawer}
        </Drawer>
      )}
      <NewChatDialogs open={newChatDialogOpen} setOpen={setNewChatDialogOpen} />

    </Box>
  );
};

export default SideBar;