import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage, removeMessagesAfter } from '../storage/chatSlice.js';
import { useParams } from 'react-router-dom';
import { Box, Divider } from '@mui/material';

import ChatInput from '../widgets/chat/ChatInput.js'
import makePOSTRequest from '../services/RestService.js';
import duplicateNewlinesExceptCodeBlocks from '../services/EncodingService.js';
import ChatMessageList from '../widgets/chat/ChatMessageList.js';
import ChatMessage from '../widgets/chat/ChatMessage.js';
import { setChatTitle } from '../storage/chatSlice.js';
import Navigation from '../widgets/navigation/Navigation.js';
import ErrorMessage from '../widgets/chat/ErrorMessage.js';
import MyAdsBanner from '../widgets/ads/MyAdsBanner.js';
import { useDrawerLeft } from '../services/LayoutService.js';
import { selectSetting } from '../storage/settingsSlice.js';
import { useMatomo } from '@datapunt/matomo-tracker-react'


const ChatWindowPage = () => {
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { chatId } = useParams();
  const hasPayed = useSelector(selectSetting('hasPayed')) || false;
  const userId = useSelector(state => state.app.userId);

  const chat = useSelector(state => state.chat.chats[chatId])
  const messages = useSelector(state => state.chat.chats[chatId].messages) || [];
  const ref = useRef();
  const { trackPageView, trackEvent } = useMatomo();

  useEffect(() => {
    trackPageView();
  }, []);

  const setShowHints = (showHints) => { }

  const handleTopic = async (input_to_send) => {
    if (chat.isTopicGenerated !== true) {
      try {
        const topic = await makePOSTRequest({ 'message': input_to_send, 'has_payed': hasPayed, 'user_id': userId }, "get_topic", () => { });
        dispatch(setChatTitle({ chatId: chatId, title: topic }));
      } catch (error) {
        console.error("Fehler beim generieren des Titels", error)
      }
    }
  }

  const handleInput = (input_to_send) => {
    trackEvent({ category: 'action', action: 'send-message' })
    dispatch(addMessage({ chatId: chatId, message: { text: input_to_send, sender: 'user' } }));
    setInput('');
  }

  const handleContent = async (input_to_send) => {
    const request = {
      'ai_name': chat.name,
      'message': input_to_send,
      'history': messages
        .filter(message => (message.sender !== 'error' && message.text !== 'Ein Fehler ist aufgetreten.'))
        .map((message) => { return { 'content': message.text, 'role': message.sender === 'user' ? 'user' : 'assistant' } }),
      'ai_args': {},
      'has_payed': hasPayed,
      'user_id': userId,
    };
    try {
      const answer = await makePOSTRequest(request, "chat", () => { });
      dispatch(addMessage({ chatId: chatId, message: { text: answer, sender: 'bot' } }));
    } catch (error) {
      console.log("Ein Fehler ist aufgetreten.", error);
      dispatch(addMessage({ chatId: chatId, message: { text: "Ein Fehler ist aufgetreten.", sender: 'error' } }));
    }
  }

  const handleSend = async () => {
    try {
      await setIsLoading(true);
      const input_to_send = duplicateNewlinesExceptCodeBlocks(input);
      handleInput(input_to_send)
      handleTopic(input_to_send)
      await handleContent(input_to_send)
    } finally {
      setIsLoading(false);
    }
  };

  const regenerate = async (index) => {
    trackEvent({ category: 'action', action: 'regenerate-message' })
    try {
      setIsLoading(true);
      dispatch(removeMessagesAfter({ chatId: chatId, index: index }))
      await handleContent(messages[index].text)
    } finally {
      setIsLoading(false);
    }
  }

  const edit = async (index, newText) => {
    trackEvent({ category: 'action', action: 'edit-message' })
    try {
      setIsLoading(true);
      const input_to_send = duplicateNewlinesExceptCodeBlocks(newText);
      dispatch(removeMessagesAfter({ chatId: chatId, index: index - 1 }))
      handleInput(input_to_send)
      await handleContent(input_to_send)
    } finally {
      setIsLoading(false);
    }
  }

  return (

    <Navigation avatar={chat.avatar} setShowHints={setShowHints}>
      <ChatMessageList
        messageComponent={ChatMessage}
        errorComponent={ErrorMessage}
        messages={messages}
        isLoading={isLoading}
        regenerate={regenerate}
        edit={edit}
      />
      <Box
        ref={ref}
        sx={{
          position: 'fixed',
          bottom: 0,
          left: useDrawerLeft,
          right: 0,
        }}
      >
        <MyAdsBanner context="Ein wenig Context" parentref={ref} />
        <Divider />
        <ChatInput handleSend={handleSend} setInput={setInput} input={input} />
      </Box>
    </Navigation>

  );
};

export default ChatWindowPage;
