import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Box, FormLabel, FormControlLabel, Switch, Dialog, DialogTitle, DialogContent, DialogActions, Button, MenuItem, Select, FormControl, InputLabel, TextField } from '@mui/material';
import axios from 'axios';
import config from '../config';
import FileUpload from '../widgets/common/FileUpload';
import { Article } from '@mui/icons-material';
import SpeakableTextBoxPaper from '../widgets/tts/SpeakableTextBoxPaper';
import { useParams } from 'react-router-dom';
import {handleFileSubmit, handleSinglePropertySubmit, loadSessionData} from '../services/RestService';
import { useSelector } from 'react-redux';
import { selectSetting } from '../storage/settingsSlice';
import { useMatomo } from '@datapunt/matomo-tracker-react'

const TextTool = ({ settings = defaultSettings, setIsLoading }) => {
    const { sessionId } = useParams();
    const currentSessionId = useRef();
    const [text, setText] = useState('');
    const [actionDialogOpen, setActionDialogOpen] = useState(false);
    const [processedText, setProcessedText] = useState('');
    const [selectedAction, setSelectedAction] = useState(false);
    const [targetLanguage, setTargetLanguage] = useState('');
    const [formatAction, setFormatAction] = useState('');
    const selectedActionRef = useRef('');
    const hasPayed = useSelector(selectSetting('hasPayed')) || false;
    const userId = useSelector(state => state.app.userId);
    const { trackEvent } = useMatomo();


    const doSetSelectedAction = useCallback((value) => {
        console.log("Setting selected action to " + value);
        setSelectedAction(value);
        selectedActionRef.current = value;
    }, []);

    const explainText = useCallback(async (text, explanationType) => {
        console.log("explaining text");
        const prompt = createExplainPrompt(explanationType);
        const explain_response = await axios.post(`${config.apiBaseUrl}/llm`, {
            instruction: prompt,
            user_input: text,
            has_payed: hasPayed,
            user_id: userId,
        });
        setProcessedText(explain_response.data.answer);
    }, [hasPayed, userId]);

    const summarizeText = useCallback(async (text) => {
        console.log("summarizing text");
        const summary_response = await axios.post(`${config.apiBaseUrl}/summarize`, { text, has_payed: hasPayed, user_id: userId, });
        setProcessedText(summary_response.data.summary);
    }, [hasPayed, userId]);

    const translateText = useCallback(async (text, targetLanguage) => {
        console.log("translating text");
        const payload = { text, targetLanguage: targetLanguage, has_payed: hasPayed, user_id: userId, };
        const response = await axios.post(`${config.apiBaseUrl}/translate`, payload);
        setProcessedText(response.data.translation);
    }, [hasPayed, userId]);

    const formatText = useCallback(async (text, formatAction) => {
        console.log("formatting text");
        const payload = { text, formatAction: formatAction, has_payed: hasPayed, user_id: userId, };
        const response = await axios.post(`${config.apiBaseUrl}/format`, payload);
        setProcessedText(response.data.formattedText);
    }, [hasPayed, userId]);


    const performAction = useCallback(async (action, text, targetLanguage, formatAction) => {
        setIsLoading(true);
        console.log("setIsLoading(true) called")
        switch (action) {
            case 'explain.translateTerms':
            case 'explain.translateSimple':
                await explainText(text, action);
                break;
            case 'summarize':
                await summarizeText(text);
                break;
            case 'translate':
                await translateText(text, targetLanguage);
                break;
            case 'format':
                await formatText(text, formatAction);
                break;
            default:
                setProcessedText(text);
                break;
        }
        console.log("Action executed, resetting loading state in 500ms")
        setTimeout(() => setIsLoading(false), 200);
    }, [explainText, formatText, summarizeText, translateText, setIsLoading]);

    const handleText = useCallback(async (text) => {
        console.log("handling text: " + text);
        console.log("selectedAction: " + selectedActionRef.current);

        setText(text);
        if (text === "Kein Text gefunden.") {
            setProcessedText(text);
            setIsLoading(false);
        } else if (settings.action && settings.action !== 'ask') {
            console.log("performing configured action on text: " + settings.action);
            performAction(settings.action, text, settings.targetLanguage, settings.formatAction);
        } else if (selectedActionRef.current && selectedActionRef.current !== '') {
            performAction(selectedActionRef.current, text, selectedActionRef.current === 'translate' ? targetLanguage : '', selectedActionRef.current === 'format' ? formatAction : '');
        } else {
            console.log("Text is available but user did not choose yet - opening dialog");
            setIsLoading(false);
            setActionDialogOpen(true);
        }
    }, [settings.action, settings.targetLanguage, settings.formatAction, targetLanguage, formatAction, performAction, setIsLoading]);

    const onExtractStart = useCallback(async () => {
        console.log("onExtractStart called - opening action dialog");
        setText('');
        setProcessedText('');
        doSetSelectedAction('');
        setTargetLanguage('');
        setFormatAction('');
        if (settings.action === 'ask') {
            setActionDialogOpen(true);
        } else {
            setIsLoading(true);
        }
    }, [settings.action, doSetSelectedAction, setIsLoading]);

    const localHandleSinglePropertySubmit = useCallback(async (name, value) => {
        handleSinglePropertySubmit(name, value, onExtractStart, handleText, setIsLoading, hasPayed, userId)
 
    }, [handleText, onExtractStart, setIsLoading, hasPayed, userId]);

    const localHandleUrlSubmit = useCallback(async (url) => {
        trackEvent({ category: 'action', action: 'text-tool-url-submit', name: url, value: 1 })
        return localHandleSinglePropertySubmit("url", url);
    }, [localHandleSinglePropertySubmit]);

    const localHandleFileSubmit = useCallback(async (event) => {
        trackEvent({ category: 'action', action: 'text-tool-file-submit', name: event.target.files[0].name, value: 1 })
        handleFileSubmit(event, onExtractStart, handleText, setIsLoading, hasPayed, userId);
    }, [handleText, onExtractStart, setIsLoading, hasPayed, userId]);

    useEffect(() => {
        if (sessionId && sessionId !== currentSessionId.current) {
            console.log("Loading session: " + sessionId)
            currentSessionId.current = sessionId;
            // Wenn eine sessionId vorhanden ist, lade die entsprechenden Daten
            loadSessionData(sessionId, onExtractStart, handleText, setIsLoading, hasPayed, userId);
        }
    }, [sessionId, onExtractStart, handleText, setIsLoading, hasPayed, userId]);



    const createExplainPrompt = (explanationType) => {
        let prompt = "Deine Aufgabe ist es, komplexe oder sehr fachliche Dokumente so umzuformulieren, dass normale Menschen ohne Spezialwissen den Text (besser) verstehen können. Im Folgenden sollst du ein Dokument erklären/Übersetzen. Gehe dabei wie folgt vor: ";

        if (explanationType === 'explain.translateTerms') {
            prompt += "Gib den Text 1 zu 1 zurück und übersetze dabei Fachbegriffe. Die Übersetzung soll immer hinter dem Fachbegriff in Klammern erfolgen.";
        } else {
            prompt += "Übersetze den Text in einfache Sprache.";
        }
        prompt += "ANTWORTE IMMER AUF DEUTSCH! Achte darauf, dass der Sinn erhalten bleibt! DIE AUSSAGEN DES TEXTES MÜSSEN KORREKT BLEIBEN! Hier der Text:";
        return prompt;
    };

    const handleDialogClose = useCallback(() => {
        setActionDialogOpen(false);
    }, []);

    const handleDialogConfirm = useCallback(() => {
        setIsLoading(true);
        trackEvent({ category: 'action', action: 'text-tool-select-action', name: selectedActionRef.current, value: 1 })
        console.info("Handling DialogConfirmation. text: " + text);
        console.info("selectedAction: " + selectedActionRef.current);

        if (text && text !== '') {
            performAction(selectedActionRef.current, text, selectedActionRef.current === 'translate' ? targetLanguage : '', selectedActionRef.current === 'format' ? formatAction : '');
        }
        setActionDialogOpen(false);
    }, [text, targetLanguage, formatAction, performAction, setIsLoading]);

    return (
        <Box sx={{ padding: 2 }}>
            {settings.showDescription && <SpeakableTextBoxPaper text={metadata.short_description} />}
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <FileUpload handleUrlSubmit={localHandleUrlSubmit} handleFileSubmit={localHandleFileSubmit} />
            </Box>
            {processedText && (
                <>
                    <SpeakableTextBoxPaper text={processedText} showCopy={true} autoSpeak={settings.autoSpeak} showShare={true} />
                </>
            )}
            <Dialog open={actionDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Aktion auswählen</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel id="action-select-label">Aktion</InputLabel>
                        <Select
                            labelId="action-select-label"
                            value={selectedAction}
                            onChange={(e) => doSetSelectedAction(e.target.value)}
                        >
                            <MenuItem value="explain.translateTerms">Erklären (Fachbegriffe übersetzen)</MenuItem>
                            <MenuItem value="explain.translateSimple">Erklären (in einfache Sprache übersetzen)</MenuItem>
                            <MenuItem value="summarize">Zusammenfassen</MenuItem>
                            <MenuItem value="translate">Übersetzen</MenuItem>
                            {/* <MenuItem value="format">Umformulieren</MenuItem> */}
                            <MenuItem value="show">Anzeigen/Vorlesen</MenuItem>
                        </Select>
                    </FormControl>
                    {selectedAction === 'translate' && (
                        <FormControl fullWidth sx={{ marginTop: 2 }}>
                            <InputLabel id="language-select-label">Zielsprache</InputLabel>
                            <Select
                                labelId="language-select-label"
                                value={targetLanguage}
                                defaultValue='Deutsch'
                                onChange={(e) => setTargetLanguage(e.target.value)}
                            >
                                <MenuItem value="Deutsch">Deutsch</MenuItem>
                                <MenuItem value="Englisch">Englisch</MenuItem>
                                <MenuItem value="Spanisch">Spanisch</MenuItem>
                                <MenuItem value="Französisch">Französisch</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                    {selectedAction === 'format' && (
                        <FormControl fullWidth sx={{ marginTop: 2 }}>
                            <InputLabel id="format-select-label">Umformulierungsziel</InputLabel>
                            <Select
                                labelId="format-select-label"
                                value={formatAction}
                                defaultValue="Geschäftsschreiben"
                                onChange={(e) => setFormatAction(e.target.value)}
                            >
                                <MenuItem value="Geschäftsschreiben">Geschäftsschreiben</MenuItem>
                                <MenuItem value="Werbeanzeige">Werbeanzeige</MenuItem>
                                <MenuItem value="nur Korrigieren">nur Korrigieren</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Abbrechen</Button>
                    <Button onClick={handleDialogConfirm} disabled={!selectedAction}>Bestätigen</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};


const TextToolSettings = ({ settings = defaultSettings, onSave }) => {
    const [action, setAction] = useState(settings.action || 'ask');
    const [targetLanguage, setTargetLanguage] = useState(settings.targetLanguage || '');
    const [formatAction, setFormatAction] = useState(settings.formatAction || '');
    const [autoSpeak, setAutoSpeak] = useState(settings.autoSpeak);
    const [showDescription, setShowDescription] = useState(settings.showDescription);
    const [displayName, setDisplayName] = useState(settings.displayName);
    const { trackEvent } = useMatomo();

    const handleSave = () => {
        trackEvent({ category: 'settings', action: 'change-texttool-setting', name: 'action', value: action })
        trackEvent({ category: 'settings', action: 'change-texttool-setting', name: 'targetLanguage', value: targetLanguage })
        trackEvent({ category: 'settings', action: 'change-texttool-setting', name: 'formatAction', value: formatAction })
        trackEvent({ category: 'settings', action: 'change-texttool-setting', name: 'autoSpeak', value: autoSpeak ? 1 : 0 })
        trackEvent({ category: 'settings', action: 'change-texttool-setting', name: 'showDescription', value: showDescription ? 1 : 0 })
        trackEvent({ category: 'settings', action: 'change-texttool-setting', name: 'displayName', value: displayName })
        onSave({ action, targetLanguage, formatAction, autoSpeak, showDescription, displayName });
    };

    const handleAutoSpeakChange = (event) => {
        setAutoSpeak(event.target.checked);
    };

    const handleShowDescriptionChange = (event) => {
        setShowDescription(event.target.checked);
    };

    return (
        <>
            <Box sx={{ padding: 2 }}>
                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                    <FormLabel component="legend">Anzeigename des Tools</FormLabel>
                    <TextField
                        labelId="name-select-label"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                    />
                </FormControl>
                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                    <FormLabel component="legend" id="action-select-label">Standardaktion</FormLabel>
                    <Select
                        labelId="action-select-label"
                        value={action}
                        onChange={(e) => setAction(e.target.value)}
                    >
                        <MenuItem value="ask">Fragen</MenuItem>
                        <MenuItem value="explain.translateTerms">Erklären (Fachbegriffe übersetzen)</MenuItem>
                        <MenuItem value="explain.translateSimple">Erklären (in einfache Sprache übersetzen)</MenuItem>
                        <MenuItem value="summarize">Zusammenfassen</MenuItem>
                        <MenuItem value="translate">Übersetzen</MenuItem>
                        {/* <MenuItem value="format">Umformulieren</MenuItem> */}
                        <MenuItem value="show">Anzeigen/Vorlesen</MenuItem>
                    </Select>
                </FormControl>
                {action === 'translate' && (
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <InputLabel id="language-select-label">Zielsprache</InputLabel>
                        <Select
                            labelId="language-select-label"
                            value={targetLanguage}
                            onChange={(e) => setTargetLanguage(e.target.value)}
                        >
                            <MenuItem value="de">Deutsch</MenuItem>
                            <MenuItem value="en">Englisch</MenuItem>
                            <MenuItem value="es">Spanisch</MenuItem>
                            <MenuItem value="fr">Französisch</MenuItem>
                        </Select>
                    </FormControl>
                )}
                {action === 'format' && (
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <InputLabel id="format-select-label">Umformulierungsziel</InputLabel>
                        <Select
                            labelId="format-select-label"
                            value={formatAction}
                            onChange={(e) => setFormatAction(e.target.value)}
                        >
                            <MenuItem value="Geschäftsschreiben">Geschäftsschreiben</MenuItem>
                            <MenuItem value="Werbeanzeige">Werbeanzeige</MenuItem>
                            <MenuItem value="nur Korrigieren">nur Korrigieren</MenuItem>
                        </Select>
                    </FormControl>
                )}
                <FormControl component="fieldset" fullWidth sx={{ marginBottom: 2 }}>
                    <FormLabel component="legend">Automatisch vorlesen</FormLabel>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={autoSpeak}
                                onChange={handleAutoSpeakChange}
                                name="autoSpeak"
                            />
                        }
                        label={autoSpeak ? "Ja" : "Nein"}
                    />
                </FormControl>
                <FormControl component="fieldset" fullWidth sx={{ marginBottom: 2 }}>
                    <FormLabel component="legend">Zeige Beschreibung an</FormLabel>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showDescription}
                                onChange={handleShowDescriptionChange}
                                name="showDescription"
                            />
                        }
                        label={showDescription ? "Ja" : "Nein"}
                    />
                </FormControl>
            </Box>
            <DialogActions>
                <Button onClick={handleSave}>Speichern</Button>
            </DialogActions>
        </>
    );
};


export const metadata = {
    name: 'Text-Werkzeug',
    icon: <Article />,
    settingsComponent: TextToolSettings,
    short_description: 'Extrahiert Texte aus Dokumenten, Fotos und Webseiten. Die Texte können zusammengefasst, erklärt, übersetzt oder umformuliert werden.',
    helpText: 'Dieses Werkzeug ermöglicht es Nutzern, Fotos aufzunehmen sowie Dateien (Bilder, Dokumente oder Audio) vom Gerät oder URLs hochzuladen, um deren Text automatisch auslesen und bearbeiten zu lassen. Dafür stehen drei Symbole zur Verfügung.\n\nAnschließend können Nutzer den extrahierten Text beispielsweise erklären, zusammenfassen oder übersetzen lassen.\n\nFür optimale Ergebnisse sollte der Text scharf sein. Es sollte nur der Text auf dem Foto sein, der relevant ist (z.B. keine Teile anderer Zeitungsartikel).'
};

export const defaultSettings = {
    action: 'ask',
    autoSpeak: false,
    displayName: 'Text-Werkzeug',
    showDescription: true
}

export default TextTool;
