import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Paper, Box, Typography } from '@mui/material';
import tools from '../loadTools';
import { useSelector } from 'react-redux';
import { selectSetting } from '../storage/settingsSlice';
import LoadingIndicator from '../widgets/LoadingIndicator';
import Navigation from '../widgets/navigation/Navigation';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const ToolInstancePage = () => {
  const { toolKeyParam, instanceId } = useParams();
  const defaultToolKey = useSelector(selectSetting('defaultToolKey'));
  const toolSettings = useSelector(state => state.dashboard.toolSettings);
  const [isLoading, setIsLoading] = useState(false);


  const toolKey = toolKeyParam ? toolKeyParam : defaultToolKey;
  const tool = tools.find(tool => tool.key === toolKey);

  const toolMetadata = tool.metadata;
  const toolSetting = instanceId ? toolSettings[instanceId] : tool.defaultSettings;
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
  }, []);

  const setShowHints = () => { };


  if (!tool) {
    return <Typography variant="h6">Tool nicht gefunden</Typography>;
  }

  return (
    <Navigation title="KI Helfer" setShowHints={setShowHints}>
      {isLoading && (<LoadingIndicator defaultText="Verarbeite Anfrage" />)}
      <Box sx={{ padding: 2 }}>
        <Paper elevation={3} sx={{ padding: 2 }}>
          <Typography variant="h5" gutterBottom>
            {toolMetadata.name}
          </Typography>
          <tool.component settings={toolSetting} setIsLoading={setIsLoading}/>
        </Paper>
      </Box>
    </Navigation>
  );
};

export default ToolInstancePage;
